import React, { useEffect } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router';
import CommissionWithdrawTable from '../../Components/CommissionWithdrawTable/CommissionWithdrawTable';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { commissionWithdrawRef, promoCollectionRef } from '../../firebase/fire-app';

const PromoCodeWithdrawDetails = () => {
    const {promoId} = useParams();
    // useEffect(()=>{
    //     commissionWithdrawRef.where("promoCode", "==", promoId).orderBy("date", "desc").get().then(()=>{
    //         console.log("fetched");
    //     })
    // },[promoId])
    const [promoCode, loading, error] = useDocumentData(promoCollectionRef.doc(promoId), {idField: "id"});
    const [withdraws, withdrawLoading, withdrawError] = useCollectionData(commissionWithdrawRef.where("promoCode", "==", promoId).orderBy("date", "desc"), {idField: "id"})
    return (
        <>
            <div className="jumbotron text-center">
                <h3>{promoId} </h3>
                <button onClick={()=> console.log(withdraws)} className="btn btn-danger">LOg</button>
            </div>
            <div className="container py-5">
                {withdrawLoading && <SectionSpinner/> }
                {withdraws && withdraws.length > 0 && <CommissionWithdrawTable allData={withdraws}/> }
            </div>
        </>
    );
};

export default PromoCodeWithdrawDetails;
