import React from 'react';
import { Spinner } from 'react-bootstrap';
import "./PageSpinner.scss"

const SectionSpinner = () => {
    return (
        <div className="section-spinner p-3 d-flex align-items-center justify-content-center">
            <Spinner animation="grow" variant="primary" />
        </div>
    );
};

export default SectionSpinner;