import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import UserDetails from '../../Components/UserDetails/UserDetails';
import { allPaymentsRef, fireStoreDB } from '../../firebase/fire-app';
import { useCollectionData } from 'react-firebase-hooks/firestore'
import PaymentsTable from './PaymenttTable';
import { customDateFormat } from '../../utilities/CustomModules';


const ViewAllPayments = () => {
    const [count, setCount] = useState(0);
    const [lastData, setLastData] = useState(null)
    const today = new Date();
    // const yesterday = new Date("2021-05-12");
    const yesterday = new Date("2021-01-10");
    // start at
    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(today)
    // Query parameters
    const [userId, setUserId] = useState(null);
    const [promo, setPromo] = useState(null);
    const [active, setActive] = useState(true);
    // Status
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const [payments, setPayments] = useState(null)
    const [totalAmount, setTotalAmount] = useState(0)

    //  query data
    // let paymentsPath = fireStoreDB.collection("Payment").doc("Payment").collection("AllPayments").where("isApproved", "==", true).limit(30);
    let paymentsPath = fireStoreDB.collection("Payment").doc("Payment").collection("AllPayments").where("isApproved", "==", true).where("specialVoucher", "==", false);
    // let paymentsPath = fireStoreDB.collection("Payment").doc("Payment").collection("AllPayments");

    useEffect(() => {
        const fetchPayments = async () => {
            let paymentQueries;
            if (userId && promo) {
                paymentQueries = await paymentsPath.where("userId", "==", userId).where("salesPersonId", "==", promo).where("date", ">=", startDate).where("date", "<=", endDate).orderBy("date", "desc").get();
            } else {
                if (userId) {
                    paymentQueries = await paymentsPath.where("userId", "==", userId).where("date", ">=", startDate).where("date", "<=", endDate).orderBy("date", "desc").get()
                }
                else if (promo) {
                    paymentQueries = await paymentsPath.where("salesPersonId", "==", promo).where("date", ">=", startDate).where("date", "<=", endDate).orderBy("date", "desc").get()
                } else {
                    paymentQueries = await paymentsPath.orderBy("date", "desc").where("date", ">=", startDate).where("date", "<=", endDate).get();
                    setLastData(paymentQueries.docs[paymentQueries.docs.length - 1])
                }
            }

            if (paymentQueries) {
                setCount(paymentQueries.docs.length);
                setPayments(
                    paymentQueries.docs.map((doc) => {
                        return { id: doc.id, ...doc.data() }
                    })
                )
            }
        }
        fetchPayments();
    }, [userId, promo])

    const fetchMorePayments = () => {
        const next = paymentsPath.where("date", "<=", startDate).orderBy("date", "desc").startAfter(lastData);
        next.get().then((documentSnapshots) => {
            let lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
            const list = [];
            documentSnapshots.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
            let updated_list = payments.concat(list);
            setPayments([...updated_list]);
            setLastData(lastVisible)
        })
    }
    useEffect(() => {
        if (payments) {
            if (payments.length > 0) {
                // console.log(payments);
                const totalSales = payments.reduce((a, b) => {
                    return { amount: a.amount + b.amount }
                })
                setTotalAmount(totalSales.amount)
            }else{
                setTotalAmount(0)
            }

        }else{
            setTotalAmount(0)
        }

    }, [payments])

    // dates update
    const onStartDateChanged = (e) => {
        const changedDate = new Date(e.target.value);
        console.log(changedDate);

        console.log(changedDate.toDateString());
        setStartDate(changedDate);

    }
    const onEndDateChanged = (e) => {
        const changedDate = new Date(e.target.value);
        console.log(changedDate);

        console.log(changedDate.toDateString());
        setEndDate(changedDate);

    }
    // useEffect(() => {
    //     setPromo("djT45xv9IgXQ7ocZDhBY5OB76563sjn");

    //     setTimeout(() => {
    //         setPromo(null);
    //     }, 600)
    // }, [startDate])
    // useEffect(() => {
    //     setPromo("djT45xv9IgXQ7ocZDhBY5OB76563sjn");

    //     setTimeout(() => {
    //         setPromo(null);
    //     }, 600)
    // }, [endDate])
    const resetDates =()=>{
        setStartDate(yesterday);
        setEndDate(today)
    }

    return (
        <>
            <div className="container py-3">
                <div className="row d-flex align-items-center">
                    <InputWrapper col="4" label="Salesperson">
                        <input onChange={(e) => setPromo(e.target.value)} name="userId" type="text" className="form-control" />
                    </InputWrapper>
                    <InputWrapper col="4" label="UserId">
                        <input onChange={(e) => setUserId(e.target.value)} name="userId" type="text" className="form-control" />
                    </InputWrapper>
                    <InputWrapper col="4" label="Total amount">
                        <input type="text" disabled placeholder={totalAmount} className="form-control" />
                    </InputWrapper>
                    <div className="col-md-4">
                        <div className="row">
                            <InputWrapper col="12" label="Start date:">
                                <input type="datetime-local" onChange={onStartDateChanged} className="form-control" id="startDate" />
                            </InputWrapper>
                            <InputWrapper col="12" label="See start date:">
                                <input type="text" disabled placeholder={startDate.toDateString()} className="form-control" id="startDateView" />
                            </InputWrapper>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <InputWrapper col="12" label="End date:">
                                <input type="datetime-local" onChange={onEndDateChanged} className="form-control" id="endDate" />
                            </InputWrapper>
                            <InputWrapper col="12" label="See end date:">
                                <input type="text" disabled placeholder={startDate.toDateString()} className="form-control" id="endDateView" />
                            </InputWrapper>
                        </div>
                    </div>

                    <div className="col-md-4 text-center">
                        <button onClick={() => resetDates()} className="btn btn-dark">Reset date</button>
                    </div>

                    {/* <div className="col-md-4 text-center">
                        <button className="btn btn-primary">This month</button>
                    </div> */}

                </div>
            </div>
            <div className="container">
                <div className="row">
                    {/* {payments && payments.map((item, index) => <SubscriptionCard index={index+1} data={item} key={item.id} />)} */}
                    {payments && <PaymentsTable data={payments} />}
                </div>
                {/* <div className="row">
                    <div className="col-md-12 text-center py-4">
                        <button onClick={fetchMorePayments} className="btn btn-danger">View more...</button>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default ViewAllPayments;

const SubscriptionCard = ({ data, index }) => {
    const basLog = () => {
        console.log(data)
    }
    const deleteDoc = () => {
        allPaymentsRef.doc(data.id).delete().then(() => {
            console.log("Deleted: ", data.id);
        })
        // allPaymentsRef.doc(data.id).update({
        //     isApproved: true
        // }).then(()=>{
        //     console.log("updated");
        // })
  
    }
    if(data.isApproved){
        console.log("confirmed");
        // allPaymentsRef.doc(data.id).update({
        //     isApproved: true
        // }).then(()=>{
        //     console.log("updated");
        // })
    }
    return (
        <>
            <div className="col-md-12">
                <div className="card mb-3">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-3">
                            {data.salesPersonId && <UserDetails title="Salesperson" id={data.salesPersonId} />}
                            <div>
                                {data.id}
                               <button onClick={basLog} className="btn btn-danger">log</button>
                                <button onClick={deleteDoc} className="btn btn-danger">delete</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h4>{index}</h4>
                            <p><span className="font-weight-bold">Amount requested: </span> <span className="h4 text-danger">{data.amountRequested} </span>  </p>
                            <p><span className="font-weight-bold">Amount approved: </span> <span className="h4 text-danger">{data.amount} </span>  </p>
                            <SpanBold title="Description" value={data.description} />
                            <SpanBold title="Date" value={new Date(data.date.seconds * 1000).toDateString()} />
                            <SpanBold title="Method" value={data.paymentMethod} />
                            <SpanBold title="TrxId" value={data.transId} />
                        </div>
                        <div className="col-md-3">
                            {/* {data.userId} */}
                            {data.userId && <UserDetails title="App user" id={data.userId} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const SpanBold = ({ title, value }) => {
    return (
        <p><span className="font-weight-bold">{title}: </span> {value}  </p>
    )
}