import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import UserDetails from '../../Components/UserDetails/UserDetails';
import { fireStoreDB } from '../../firebase/fire-app';
import VoucherCodesContainer from './VoucherCodesContainer';


const voucherCollectionRef = fireStoreDB.collection("Payment").doc("Discount").collection("VoucherCodes");
const ViewVoucherCodes = () => {
    const [showLoad, setShowLoad] = useState(false);
    const { register, handleSubmit } = useForm();
    const [userId, setUserId] = useState(null);

    const [values, loading, error] = useCollectionData(voucherCollectionRef.orderBy("createdAt", "desc"), { idField: "id" });

    const onVoucherCodeAdded = (data, e) => {
        data.amount = Number(data.amount)
        data.expiryDate = new Date(data.expiryDate);
        data.createdAt = new Date();
        data.isUsed = false;
        const pCode = data.voucherCode;
        delete data.voucherCode
        voucherCollectionRef.doc(pCode).set(data).then(res => {
            e.target.reset();
        })

    }
    const onUserIdChanged = (e) => {
        setUserId(e.target.value)
    }
    return (
        <>

            <Container>
                {/* <button onClick={() => console.log(values)} className="btn btn-primary">log</button> */}
                <h3 className="text-primary text-center my-4">Add New Voucher Code</h3>
                <form className="mt-0 row d-flex align-items-center" onSubmit={handleSubmit(onVoucherCodeAdded)}>
                    <div className="col-md-8">
                        <Row>
                            <InputWrapper col="12" label="UserId">
                                <input onChange={onUserIdChanged} ref={register()} name="userId" type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="7" label="Voucher Code">
                                <input ref={register({ required: true })} name="voucherCode" type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="5" label="Amount">
                                <input ref={register({ required: true })} name="amount" type="number" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="7" label="Expiry">
                                <input ref={register({ required: true })} name="expiryDate" type="datetime-local" className="form-control" />
                            </InputWrapper>
                            <div className="col-md-6">
                                <button type="submit" className="btn btn-primary">Add Voucher Code</button>
                            </div>
                        </Row>
                    </div>
                    <div className="col-md-4">
                        {userId && <UserDetails id={userId} />}
                    </div>
                </form>
            </Container>
            <div className="container pt-4">
                <hr />
            </div>
            <div className="mt-4 bg-light">
                {loading && <SectionSpinner />}
                {values && <VoucherCodesContainer words={values} />}
                {showLoad && <SectionSpinner />}

            </div>
        </>
    );
};

export default ViewVoucherCodes;