import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { promoCollectionRef } from '../../firebase/fire-app';
import PromoTable from './PromoTable';

const ViewTrackPromos = () => {
    const [promos, loading, err] = useCollectionData(promoCollectionRef.orderBy("createdAt", "desc"), {idField: "id"});

    return (
        <>
            <div className="jumbotron text-center">
                <h2>Promo Codes</h2>
                
            </div>
            {
                promos && <PromoTable data={promos}/>
            }
        </>
    );
};

export default ViewTrackPromos;