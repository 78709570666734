import { useState } from 'react';
import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";
import { Layout, Menu } from 'antd';
import {

    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuth';


const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const SidebarMenu = ({ collapsed, setCollapsed }) => {
    const showMenu = true;
    const { currentUser, setCurrentUser } = useAuth()
    const onLogOut = () => {
        firebase.auth().signOut().then(res => {
            sessionStorage.removeItem("token")
        }).catch(err => {
            alert(err);
        })
    }

    return (
        <>
            <div className="d-none d-md-block">
                <Sider
                    title="Prottoy"
                    style={{
                        height: '100vh',

                    }}
                    trigger={null}
                    collapsible collapsed={collapsed}
                >
                    <div className="logo" >Prottoy</div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                        <Menu.Item key="1" icon={<UserOutlined />}>
                            <Link to="/">Home</Link>
                        </Menu.Item>
                        <Menu.Item key="2" icon={<UserOutlined />}>
                            <Link to="/all-subscriptions">Subscriptions</Link>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<UserOutlined />}>
                            <Link to="/all-payments">Transactions</Link>
                        </Menu.Item>
                        {/* <Menu.Item key="16" icon={<UserOutlined />}>
                            <Link to="/special-voucher">Special Voucher</Link>
                        </Menu.Item> */}
                        {/* 9 - 10 */}
                        {/* <SubMenu key="sub3" icon={<UserOutlined />} title="Subscriptions" >
                           
                            <Menu.Item key="10">
                                <Link to="/all-subscriptions">All Subscriptions</Link>
                            </Menu.Item>
                            <Menu.Item key="101">
                                <Link to="/active-subscriptions">Active Subscriptions</Link>
                            </Menu.Item>
                        </SubMenu> */}
                        {/* 2 - 4 */}
                        <SubMenu key="sub1" icon={<UserOutlined />} title="Users">
                            <Menu.Item key="2">
                                <Link to="/update-user">Update User</Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Link to="/all-users">All users</Link>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <Link to="/new-installation">New Installation</Link>
                            </Menu.Item>
                            {/* <Menu.Item key="411">
                                <Link to="/all-salespersons">Salespersons' List</Link>
                            </Menu.Item> */}

                        </SubMenu>

                        {/* 5 - 8 */}
                        {/* <SubMenu key="sub2" icon={<UserOutlined />} title="Payments">
                           
                            <Menu.Item key="6">
                                <Link to="/all-payments">All Payments</Link>
                            </Menu.Item>
                   
                        </SubMenu> */}

                        {/* 11 - 13 */}
                        {/* <SubMenu key="sub4" icon={<UserOutlined />} title="Codes">
                            <Menu.Item key="11">
                                <Link to="/promo-codes">Promo Codes</Link>
                            </Menu.Item>
                            <Menu.Item key="12">
                                <Link to="/track-promos">Track promos</Link>
                            </Menu.Item>
                            <Menu.Item key="13">
                                <Link to="/voucher-codes">Voucher Codes</Link>
                            </Menu.Item>
                        </SubMenu> */}
                        {/* 14 - 15 */}
                        {/* <SubMenu key="sub5" icon={<UserOutlined />} title="Withdraw">
                            <Menu.Item key="14">
                                <Link to="/withdraw/salesperson">From Salesperson</Link>
                            </Menu.Item>
                            <Menu.Item key="15">
                                <Link to="/withdraw/affiliates">From Affiliates</Link>
                            </Menu.Item>
                        </SubMenu> */}
                        <Menu.Item key="16">
                            {currentUser ? <button onClick={() => onLogOut()} className="btn btn-danger">Log out</button> : <Link to="/login">Login</Link>}
                        </Menu.Item>
                    </Menu>
                </Sider>
            </div>
            <div className="d-block d-md-none">
                <Sider
                    title="Prottoy"
                    style={{
                        height: '100vh',
                        position: 'fixed',
                        zIndex: "20"
                    }}
                    breakpoint="sm"
                    collapsedWidth="0"


                // onBreakpoint={broken => {
                //     console.log(broken);
                // }}

                // onCollapse={(collapsed, type) => {
                //     console.log(collapsed, type);
                // }}


                >
                    <div className="logo" >Prottoy</div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                        <Menu.Item key="1" icon={<UserOutlined />}>
                            <Link to="/">Home</Link>
                        </Menu.Item>
                        {/* 2 - 4 */}
                        <SubMenu key="sub1" icon={<UserOutlined />} title="Users">
                            <Menu.Item key="2">
                                <Link to="/update-user">Update User</Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Link to="/all-users">All users</Link>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <Link to="/all-salespersons">Salespersons' List</Link>
                            </Menu.Item>
                        </SubMenu>
                        {/* 5 - 8 */}
                        <SubMenu key="sub2" icon={<UserOutlined />} title="Payments">
                            <Menu.Item key="5">
                                <Link to="/">Payment requests</Link>
                            </Menu.Item>
                            <Menu.Item key="6">
                                <Link to="/all-payments">All Payments</Link>
                            </Menu.Item>
                            <Menu.Item key="7">
                                <Link to="/all-payments">All Payments</Link>
                            </Menu.Item>
                            <Menu.Item key="8">
                                <Link to="/add-salesperson">Add Salesperson</Link>
                            </Menu.Item>
                        </SubMenu>
                        {/* 9 - 10 */}
                        <SubMenu key="sub3" icon={<UserOutlined />} title="Subscriptions" >
                            <Menu.Item key="9">
                                <Link to="/add-subscription">Add Subscription</Link>
                            </Menu.Item>
                            <Menu.Item key="10">
                                <Link to="/all-subscriptions">All Subscriptions</Link>
                            </Menu.Item>
                        </SubMenu>
                        {/* 11 - 13 */}
                        <SubMenu key="sub4" icon={<UserOutlined />} title="Codes">
                            <Menu.Item key="11">
                                <Link to="/promo-codes">Promo Codes</Link>
                            </Menu.Item>
                            <Menu.Item key="12">
                                <Link to="/track-promos">Track promos</Link>
                            </Menu.Item>
                            <Menu.Item key="13">
                                <Link to="/voucher-codes">Voucher Codes</Link>
                            </Menu.Item>
                        </SubMenu>
                        {/* 14 - 15 */}
                        <SubMenu key="sub5" icon={<UserOutlined />} title="Withdraw">
                            <Menu.Item key="14">
                                <Link to="/withdraw/salesperson">From Salesperson</Link>
                            </Menu.Item>
                            <Menu.Item key="15">
                                <Link to="/withdraw/affiliates">From Affiliates</Link>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key="16">
                            {currentUser ? <button onClick={() => onLogOut()} className="btn btn-danger">Log out</button> : <Link to="/login">Login</Link>}
                        </Menu.Item>
                    </Menu>
                </Sider>
            </div>
        </>
    );
};

export default SidebarMenu;