import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import { userInfoCollectionRef } from '../../firebase/fire-app';
import NewInstallationTable from './TestInstallationTable';
import ReactPDF, { Page, PDFDownloadLink, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        width: '100%',
        padding: '40px'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    tableCell:{
        paddingLeft: "10px"
    },
    viewer:{
        width: "100%",
        height: '600px'
    }
});
const NewInstallation = () => {
    const today = new Date();
    const thirtyBack = new Date();
    thirtyBack.setDate(thirtyBack.getDate() - 7);
    const [startDate, setStartDate] = React.useState(thirtyBack);
    const [endDate, setEndDate] = React.useState(today);


    const userCollectionQuey = userInfoCollectionRef.where('createdAt', '>', startDate)
        .where('createdAt', '<', endDate).orderBy('createdAt', 'desc');
    const [newUsers, userLoading, userError] = useCollectionData(userCollectionQuey, { idField: 'id' });

    const testLog = () => {
        alert(JSON.stringify(newUsers));
    }
    // dates update
    const onStartDateChanged = (e) => {
        const changedDate = new Date(e.target.value);
        console.log(changedDate);

        console.log(changedDate.toDateString());
        setStartDate(changedDate);

    }
    const onEndDateChanged = (e) => {
        const changedDate = new Date(e.target.value);
        console.log(changedDate);

        console.log(changedDate.toDateString());
        setEndDate(changedDate);

    }
    const lastNDays = (n) => {
        const nBack = new Date();
        nBack.setDate(nBack.getDate() - n);
        setStartDate(nBack);
    }
    let timeSpan = endDate - startDate;
    timeSpan = Math.ceil(((timeSpan / (1000 * 60 * 60 * 24))));
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-10 offset-md-2 text-left">
                        <div className="btn-group mb-4" role="group">
                            <button type="button" className="btn btn-dark" onClick={() => lastNDays(30)}>Last 30 days</button>
                            <button type="button" className="btn btn-primary" onClick={() => lastNDays(7)}>Last 7 days</button>
                            <button type="button" className="btn btn-danger" onClick={() => lastNDays(1)}>Today</button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <InputWrapper col="12" label="Start date:">
                                <input type="datetime-local" onChange={onStartDateChanged} className="form-control" id="startDate" />
                            </InputWrapper>
                            <InputWrapper col="12" label="See start date:">
                                <input type="text" disabled placeholder={startDate.toDateString()} className="form-control" id="startDateView" />
                            </InputWrapper>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <InputWrapper col="12" label="End date:">
                                <input type="datetime-local" onChange={onEndDateChanged} className="form-control" id="endDate" />
                            </InputWrapper>
                            <InputWrapper col="12" label="See end date:">
                                <input type="text" disabled placeholder={endDate.toDateString()} className="form-control" id="endDateView" />
                            </InputWrapper>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <h3 className="display-2 pb-0 mb-0">
                                {newUsers ? newUsers.length : 0}
                            </h3>
                            <p><i>new users in last {timeSpan} days. </i></p>
                        </div>

                    </div>
                </div>
            </div>
            {/* <h1 className="text-center">New Installation </h1> */}
            {/* <button onClick={testLog}>Log</button> */}
            {/* {<NewInstallationTable data={newUsers} />} */}
            <hr />
            <PDFDownloadLink document={<NewInstallationTable newUsers={newUsers} />} fileName="New Installation Report.pdf">
                {({ blob, url, loading, error }) =>
                    loading ? 'Loading document...' : 'Download now!'
                }
            </PDFDownloadLink>
            <hr/>
            <PDFViewer style={styles.viewer}>
                <NewInstallationTable newUsers={newUsers} />
            </PDFViewer>
           

        </>
    );
};

export default NewInstallation;