import { useState } from 'react';
import { Card, Col, Modal, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InputWrapper from '../InputWrapper/InputWrapper';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../Hooks/useAuth';
import UserDetails from '../UserDetails/UserDetails';
import { userInfoCollectionRef } from '../../firebase/fire-app';

const UserCardComponent = ({ data }) => {
    const [modalShow, setModalShow] = useState(false);

    const {
        name,
        phone,
        email,
        district,
        college,
        collegeId,
        photoUrl,
        uid
    } = data;
    return (
        <>
            {data && <Card className="p-4 pr-0 mb-3">
                <Row className="d-flex align-items-center" >
                    <Col md={2}>
                        {photoUrl ? (
                            <img alt={name}
                                className="avatar rounded-square"
                                src={photoUrl} />
                        ) : (
                            <span>no image</span>
                        )}
                    </Col>
                    <Col md={4}>
                        <h4>{name}</h4>
                        <p>{email} </p> <p>{phone} </p>
                    </Col>
                    <Col md={3} className="text-center">
                        {/* <p className="font-weight-bold mb-3">{uid}</p> */}
                        <p className="font-weight-bold mb-2">{data.userType} </p>

                        <CopyToClipboard text={uid}>
                            <button className="btn btn-dark btn-sm">Click to copy user-id</button>
                        </CopyToClipboard>

                    </Col>
                    <Col md={3}>
                        <button onClick={() => setModalShow(true)} className="btn btn-danger">Update userType?</button>
                    </Col>

                </Row>
            </Card>}
            <MyVerticallyCenteredModal data={data}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

        </>
    );
};

export default UserCardComponent;


function MyVerticallyCenteredModal(props) {
    const { register, handleSubmit } = useForm();
    const [userId, setUserId] = useState(props.data.uid);
    const { currentUser } = useAuth()
    const onPaymentAdded = (data) => {
        data.userId = props.data.uid;
        userInfoCollectionRef.doc(data.userId).update({
            userType: data.userType,
            typeUpdatedBy: currentUser.id
        })
    }
        const onUserIdChanged = (e) => {
            setUserId(e.target.value)
        }
        const userTypes = ["Admin", "Campus Ambassador", "Teacher", "Free User", "Support Member", "Salesperson", "Student"];

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeBu tton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
          </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex align-items-center">
                        <div className="col-md-8">
                            <form onSubmit={handleSubmit(onPaymentAdded)}>
                                <div className="row">
                              
                                    <InputWrapper col={6} label="User Type: ">
                                        <select ref={register({ required: true })} name="userType" className="custom-select">
                                            {userTypes.map(item => <option value={item}>{item}</option>)}
                                        </select>
                                    </InputWrapper>

                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary">Update user</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className="col-md-4">
                            {userId && <UserDetails id={props.data.uid} />}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }