// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBBYG5hOkmcSoQUdNmWnMVYzyVPf-u48Rs",
    authDomain: "prottoy-16bf0.firebaseapp.com",
    databaseURL: "https://prottoy-16bf0.firebaseio.com",
    projectId: "prottoy-16bf0",
    storageBucket: "prottoy-16bf0.appspot.com",
    messagingSenderId: "104681094193",
    appId: "1:104681094193:web:c1999ae0cf3e53f0f52910",
    measurementId: "G-0KZB9NGPQ4"
};
export default firebaseConfig