import Avatar from 'antd/lib/avatar/avatar';
import React, { useEffect } from 'react';
import { Spin } from "antd";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { userInfoCollectionRef } from '../../firebase/fire-app';

const UserShortInfo = ({ uid, setProperty, setUser }) => {
    const [user, loading, error] = useDocumentData(userInfoCollectionRef.doc(uid))
    if(user){
        setProperty(user.userType)
        if(setUser){
            setUser(user)
        }
    }
    return (
        <>
            {loading ? <Spin /> : <ShortInfo data={user} uid={uid} />}
        </>
    );
};

export default UserShortInfo;

const ShortInfo = ({ data, uid }) => {
    return (
        <>
            {data ? <div>
                {/* <Avatar size="small" src={data.photoUrl} /> */}
                <p className="font-16 no-break" ><>{data.name} </></p>
            </div> : uid }
        </>
    )
}