import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputWrapper from "../../Components/InputWrapper/InputWrapper"
import UserDetails from "../../Components/UserDetails/UserDetails";
import { fireStoreDB, paymentWithdrawCollectionRef, salespersonCollectionRef, serverTimestamp } from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import firebase from "firebase";

const promoCollectionRef = fireStoreDB
    .collection("Payment")
    .doc("Discount")
    .collection("PromoCodes");

const WithdrawFromSalesperson = () => {
    const { register, handleSubmit } = useForm();
    const { currentUser } = useAuth()
    // states
    const [userId, setUserId] = useState(null);
    const [salespersonDetails, setSalespersonDetails] = useState(null);
    const [userDetails, setUserDetails] = useState(null)
    const onUserIdChanged = (e) => {
        setUserId(e.target.value)
    }
    useEffect(() => {
        if (userId) {
            salespersonCollectionRef.doc(userId).onSnapshot((doc) => {
                if (doc.exists) {
                    setSalespersonDetails({ id: doc.id, ...doc.data() })
                } else {
                    setSalespersonDetails(null)
                }

            })
        }
    }, [userId]);

    const processWithdraw = (data, e) => {
        if (data.withdrawAmount > salespersonDetails.currentBalance || data.withdrawAmount <= 0) {
            alert("Invalid withdraw amount! Please check withdraw amount again.")
        } else {
            if (userId && currentUser) {
                data.withdrawAmount = Number(data.withdrawAmount);
                data.salespersonId = userId;
                data.withdrawBy = currentUser.id;
                data.date = serverTimestamp;
                console.log(data);
                salespersonCollectionRef.doc(userId).update({
                    currentBalance: firebase.firestore.FieldValue.increment(-data.withdrawAmount),
                    totalWithdraw: firebase.firestore.FieldValue.increment(data.withdrawAmount),
                    lastWithdraw: serverTimestamp
                }).then(() => {
                    paymentWithdrawCollectionRef.add(data).then(()=>{
                        e.target.reset()
                    })
                })

            }


        }
    }
    return (
        <>
            <div className="jumbotron text-center">
                <h3 className="text-center">Withdraw from Salesperson</h3>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <form onSubmit={handleSubmit(processWithdraw)} >
                            <div className="row">
                                <InputWrapper col="8" label="UserId">
                                    <input onChange={onUserIdChanged} type="text" name="userId" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="5" label="Amount">
                                    <input ref={register({ required: true })} name="withdrawAmount" type="num" className="form-control" />
                                </InputWrapper>
                                <div className="col-12">
                                    {(salespersonDetails && (salespersonDetails.currentBalance > 0)) ? <button className="btn btn-primary">Proceed withdraw</button>
                                        : <button disabled className="btn btn-danger">Not enough balance</button>}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        {salespersonDetails &&
                            <div className="p-4 mb-4 bg-primary word-preview text-white mb-3" >

                                <p>Total amount: <span>{salespersonDetails.totalAmount} </span> </p>
                                <p>Current balance: <span>{salespersonDetails.currentBalance} </span> </p>
                                <p>Total withdraw: <span>{salespersonDetails.totalWithdraw} </span> </p>
                            </div>
                        }
                        {userId && <UserDetails id={userId} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithdrawFromSalesperson;