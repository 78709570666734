import React, { useEffect, useState } from 'react';
import "./ViewLogin.scss"
// import * as firebase from "firebase/app";
import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";

import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuth';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';

const ViewLogin = () => {
    let history = useHistory();
    let location = useLocation();
    const {currentUser} = useAuth()

    const [loading, setLoading] = useState(false)

    let { from } = location.state || { from: { pathname: "/" } };

    useEffect(()=>{
        setLoading(true);
        setTimeout(()=>{
            if(currentUser){
                setLoading(false)
                history.replace(from)
            }else(
                setLoading(false)
            )
        },2600)
 

    },[currentUser])
    const handleGoogleSignIn = () => {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function (result) {
            const { displayName, email } = result.user;
            const signedInUser = { name: displayName, email }
            history.replace(from.pathname);

        }).catch(function (error) {
            const errorMessage = error.message;
            alert(errorMessage);
        });
    }


    // const storeAuthToken = () => {
    //     firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
    //         .then(function (idToken) {
    //             sessionStorage.setItem('token', idToken);
    //             history.replace(from);
    //         }).catch(function (error) {
    //             // Handl
    //         });
    // }
    const signOut = () => {
        firebase.auth().signOut().then(res => {
            sessionStorage.removeItem("token")
        }).catch(err => {
            alert(err);
        })
    }
    return (
        <>
            <section className="vh-80 d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            {loading ?
                            <SectionSpinner/>
                             :  <div className="login-card text-center p-5 bg-dark">
                                <Link to="/" className="h2 d-block text-white mb-4">Prottoy</Link>
                                <button onClick={handleGoogleSignIn} className="btn btn-danger">Log In with Google</button>
                                <div className="mt-3">
                                    <button onClick={signOut} className="btn btn-danger">Sign out</button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ViewLogin;