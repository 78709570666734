import { useState } from 'react';

import { Link } from 'react-router-dom';
import { AiFillTag } from "react-icons/ai";
import { useForm } from 'react-hook-form';
import { fireStoreDB } from '../../firebase/fire-app';
import UserDetails from '../../Components/UserDetails/UserDetails';
import "./ViewVoucherCodes.scss"


const promoCollectionRef = fireStoreDB.collection("Payment").doc("Discount").collection("VoucherCodes");

const VoucherCodesContainer = ({ words }) => {
    const [currentPromo, setCurrentPromo] = useState(words[0]);
    const [edit, setEdit] = useState(false);

    const { register, handleSubmit } = useForm();

    // Edit word function
    const onPromoCodeUpdated = (data, e) => {
        data.createdAt = new Date();

        promoCollectionRef.doc(currentPromo.id).set(data).then(res => {
            e.target.reset();
            setEdit(false);
        })
    }

    // edit option
    const setEditOption = (word) => {
        setCurrentPromo(word);
        setEdit(true);
    }
    // delete
    const deleteWord = (id) => {
        promoCollectionRef.doc(id).delete().then(res => {
            setCurrentPromo(words[0]);
        })
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="py-2 d-flex flex-wrap">
                            {words.map(item => <button onClick={() => setCurrentPromo(item)} className="btn btn-primary m-2" to={"/words/edit/" + item.id}> {item.id} <AiFillTag /> </button>)}
                        </div>
                    </div>
                    <div className="col-md-4">
                        {currentPromo &&
                            <div className="p-4 mb-4 bg-primary word-preview text-white mb-3" >
                                <p>Code: <span>{currentPromo.id} </span> </p>
                                <p>User: <span>{currentPromo.userId} </span> </p>
                                <p>Amount: <span>{currentPromo.amount} </span> </p>

                                <div className="d-flex justify-content-between mt-3">
                                    {/* <button onClick={() => setEditOption(currentPromo)} className="btn btn-danger btn-sm">Edit this word</button> */}
                                    <button onClick={() => deleteWord(currentPromo.id)} className="btn btn-danger btn-sm">Delete </button>
                                </div>
                            </div>
                        }
                        {currentPromo && <UserDetails id={currentPromo.userId} />}

                        {/* {edit &&
                            <form onSubmit={handleSubmit(onWordUpdated)} className="p-4 bg-primary word-preview mb-5">
                                <input defaultValue={currentPromo.word} ref={register()} type="text" name="word" className="form-control mb-3" placeholder="Write the word" />
                                <input defaultValue={currentPromo.meaning} ref={register()} type="text" name="meaning" className="form-control mb-3" placeholder="Write the meaning" />
                                <input defaultValue={currentPromo.partsOfSpeech} ref={register()} type="text" className="form-control mb-3" name="partsOfSpeech" placeholder="Parts of Speech..." />
                                <input defaultValue={currentPromo.pronunciation} ref={register()} type="text" className="form-control mb-3" name="pronunciation" placeholder="Pronunciation..." />
                                <input defaultValue={currentPromo.funFact} ref={register()} type="text" name="funFact" className="form-control mb-3" placeholder="Fun fact of this word" />
                                <button type="submit" className="btn btn-danger">Update word</button>
                            </form>} */}

                    </div>
                </div>

            </div>
        </>
    );
};

export default VoucherCodesContainer;