import { Avatar, Breadcrumb, Layout } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import SidebarMenu from './_sidebarMenu';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,

} from '@ant-design/icons';
import "./AdminLayout.scss"
import { useAuth } from '../../Hooks/useAuth';

const { Header, Content, Footer } = Layout;


const AdminLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(true)
    let x = window.matchMedia("(min-width: 756px)");
    const { currentUser } = useAuth()

    useEffect(() => {
        if (x.matches) {
            setCollapsed(false)
        } else {
            setCollapsed(true)
        }
    }, [])
    console.log(x.matches)

    const toggle = () => {
        setCollapsed(!collapsed);
    }
    return (
        <>
            {/* Whole Layout */}
            <Layout>
                {/* Menu */}
                <SidebarMenu collapsed={collapsed} setCollapsed={setCollapsed} />
                {/* site contents */}
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        <div className="d-none d-md-block">
                            {
                                collapsed ? <MenuUnfoldOutlined onClick={toggle} className="trigger" /> : <MenuFoldOutlined onClick={toggle} className="trigger" />
                            }
                            <span className="mr-auto d-inline-block">
                                {currentUser && currentUser.name}
                            </span>
                            {/* {currentUser && <Avatar size="large" src={currentUser.photoUrl} />} */}
                        </div>
                    </Header>
                    <Content style={{ margin: '0px 16px 0' }}>
                        {children}
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Prottoy Visual Learning App &copy; 2021 </Footer>
                </Layout>
            </Layout>
        </>
    );
};

export default AdminLayout;