import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import UserShortInfo from '../../Components/UserDetails/UserShortInfo';
import { customDateFormat } from '../../utilities/CustomModules';

const PromoTable = ({data}) => {
    return (
        <>
            <section className="py-4">
                        <div className="container">
                            <Table responsive bordered hover variant="primary">
                                <thead>
                                    <tr className="">
                                        <th>#</th>
                                        <th>Promo Code</th>
                                        <th>CreatedAt</th>
                                        <th>AmountsUpdated</th>
                                        <th>User</th>
                                        <th>UserType</th>
                                        <th>Total Sales</th>
                                        <th>Commission Earned</th>
                                        <th>Withdrawn Amount</th>
                                        <th>Current Commission</th>
                                        <th>Promo Code Used</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, index) => <PromoCodeRow key={item.id} data={item} serial={index} />)}
                                    {data && data.length == 0 && <td className="font-weight-bold h5 ml-4 text-center text-danger" >No sale yet.</td> }
                                {/* <td>  <button onClick={()=> console.log(data)} className="btn btn-dark">Log</button> </td> */}
                                </tbody>
                            </Table>

                        </div>
                    </section>
        </>
    );
};

export default PromoTable;

const PromoCodeRow = ({ data, serial }) => {
    const [show, setShow] = useState(true);
    const [property, setProperty] = useState(null);
    return (
        <>
           {show &&  <tr className="" >
                <td>{serial + 1} </td>
                <td>{data.id} </td>
                <td>{customDateFormat(data.createdAt)}</td>
                <td>{customDateFormat(data.affiliateUpdate)}</td>
                <td><UserShortInfo setProperty={setProperty} uid={data.userId}/> </td>
                <td>{property && property} </td>
                <td>{data.totalSubscriptionAmount} </td>
                <td>{data.totalCommission} </td>
                <td>{data.withdrawCommission} </td>
                <td>{data.currentCommission} </td>
                <td>{data.used} </td>
            </tr>}
        </>
    )
}