import { useState } from "react";
import { Table } from "react-bootstrap";
import UserShortInfo from "../../Components/UserDetails/UserShortInfo";
import { customDateFormat } from '../../utilities/CustomModules';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const SubscriptionTable = ({ data }) => {
    return (
        <>
            <section className="py-4">
            <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>
                <div className="w-100">
                    <Table id="table-to-xls" responsive bordered hover variant="primary">
                        <thead>
                            <tr className="">
                                <th>#</th>
                                <th>Date</th>
                                <th>User</th>
                                <th>Amount</th>
                                <th>Promo</th>
                                <th>Course</th>
                                <th>StartAT</th>
                                <th>EndAt</th>
                                {/* <th>Approver</th> */}
                                <th>Method</th>
                                <th>TrxID</th>
                                {/* <th>Note</th> */}
                                {/* <th>SubscriptionID</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, index) => <SubscriptionCard key={item.id} data={item} serial={index} />)}
                            {data && data.length == 0 && <td className="font-weight-bold h5 ml-4 text-center text-danger" >No sale yet.</td>}
                            {/* <td>  <button onClick={()=> console.log(data)} className="btn btn-dark">Log</button> </td> */}
                        </tbody>
                    </Table>

                </div>
            </section>
        </>
    );
};

export default SubscriptionTable;


const SubscriptionCard = ({ data, serial }) => {
    const [property, setProperty] = useState(null);

    return (
        <>
            <tr className="font-16" >
                <td>{serial + 1} </td>
                <td>{customDateFormat(data.date)}</td>
                <td><UserShortInfo setProperty={setProperty} uid={data.userId}/> </td>
                <td>{data.amount} </td>
                <td>{data.promoCode}</td>
                <td>{data.course.name} </td>
                <td> {customDateFormat(data.course.startAt)} </td>
                <td> {customDateFormat(data.course.endAt)} </td>
                {/* <td>{data.salesperson && <UserShortInfo setProperty={setProperty} uid={data.salesperson}/>} </td> */}
                <td>{data.paymentMethod} </td>
                <td>{data.transId} </td>
                {/* <td>{data.description} </td> */}
                {/* <td>{data.id} </td> */}
            </tr>
        </>
    )
}