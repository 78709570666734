import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import UserDetails from '../../Components/UserDetails/UserDetails';
import { fireStoreDB, salespersonCollectionRef, serverTimestamp, userSubscriptionsRef } from '../../firebase/fire-app';
import { useAuth } from "../../Hooks/useAuth";
import firebase from 'firebase'
import { ambassadors } from './campusamb';
import { secondMonthFree } from './secondmonth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { timeStampToDate } from '../../utilities/CustomModules';

const subscriptionDocRef = fireStoreDB.collection("Payment").doc("Subscription");
const salesPersonRef = fireStoreDB.collection("Payment").doc("SalesPersons").collection("SalesPersons");
const userInfoCollectionRef = fireStoreDB
    .collection("metadata")
    .doc("userMetadata")
    .collection("userInfo");

const CAFreeOneMonth = () => {
    const { register, handleSubmit } = useForm();
    const [userId, setUserId] = useState(null);
    const { currentUser } = useAuth()

    const [courseInfo, setCourseInfo] = useState(null);

    const updateSubscriptions = (data, userId, index) => {
        const courseId = data.course.courseId;
        subscriptionDocRef.collection("AllSubscriptions").add(data).then(() => {
            if (data.course.courseId == "app") {
                subscriptionDocRef.collection("Users").doc(data.userId).set({ app: data.course }, { merge: true }).then(() => {
                    console.log("Updated: ", userId, " : ", index + 1);
                })
            } else {
                subscriptionDocRef.collection("Users").doc(data.userId).set({ subjects: { [courseId]: data.course } }, { merge: true }).then(() => {
                    console.log("Updated: ", userId, " : ", index + 1);
                })
            }

        })
    }

    const courses = ["app", "PH01", "CH01", "MT01", "BIO01", "BIO02", "questionBank"];
    const courseNames = ["App", "Physics-I", "Chemistry-I", "Math-I", "Biology-I", "Biology-II", "Question-Bank"];
    const onPaymentAdded = (data, e) => {
        data.date = serverTimestamp;
        data.description = "CA one month free subscription!"
        data.amount = 0;
        data.isRenew = false;
        data.course.startAt = new Date(data.course.startAt);
        data.course.endAt = new Date(data.course.endAt);
        data.salesperson = currentUser.id;
        const courseId = data.course.courseId;
        console.log(data.course)
        setCourseInfo(data.course)
        // secondMonthFree.map((item, index) => {
        //     data.userId = item
        //     console.log(item, index, data);
        //     updateSubscriptions(data, userId, index)
        // })

        // subscriptionDocRef.collection("AllSubscriptions").add(data).then(() => {
        //     if (data.course.courseId == "app") {
        //         subscriptionDocRef.collection("Users").doc(data.userId).set({ app: data.course }, { merge: true }).then(() => {
        //             e.target.reset();
        //         })
        //     } else {
        //         subscriptionDocRef.collection("Users").doc(data.userId).set({ subjects: { [courseId]: data.course } }, { merge: true }).then(() => {
        //             e.target.reset()
        //         })
        //     }

        // })

        // userInfoCollectionRef.doc(data.userId).update({
        //     userType: data.userType
        // })
        // const salespersonId = currentUser.id;
        // const expiryDate = new Date(new Date().getTime() + (300 * 24 * 60 * 60 * 1000))
        // salespersonCollectionRef.doc(salespersonId).update({
        //     totalAmount: firebase.firestore.FieldValue.increment(data.amount),
        //     currentBalance: firebase.firestore.FieldValue.increment(data.amount)
        // })
    }

    const onUserIdChanged = (e) => {
        setUserId(e.target.value)
    }
    const userTypes = ["Admin", "Campus Ambassador", "Free User", "Support Member", "Salesperson", "Student"];

    return (
        <>
            {/* <div>
                <div className="jumbotron text-center">
                    <h3 className="text-primary">Add Subscription</h3>
                </div>
            </div> */}
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-10">
                        <form onSubmit={handleSubmit(onPaymentAdded)}>
                            <div className="row">

                                <InputWrapper col="5" label="Promo Code">
                                    <input ref={register({})} name="promoCode" type="text" className="form-control" />
                                </InputWrapper>



                                {/* course details */}
                                <InputWrapper col="6" addClass="mt-3" label="Course name">
                                    <select ref={register({ required: true })} name="course.name" className="custom-select">
                                        <option value={false} selected>Choose</option>
                                        {courseNames.map(item => <option value={item}>{item} </option>)}
                                    </select>
                                </InputWrapper>
                                <InputWrapper col="6" addClass="mt-3" label="Course Id">
                                    <select ref={register({ required: true })} name="course.courseId" className="custom-select">
                                        <option value={false} selected>Choose</option>
                                        {courses.map(item => <option value={item}>{item} </option>)}
                                    </select>
                                </InputWrapper>
                                <InputWrapper col="6" addClass="mt-3" label="Start at">
                                    <input ref={register({ required: true })} name="course.startAt" type="datetime-local" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="6" addClass="mt-3" label="End at">
                                    <input ref={register({ required: true })} name="course.endAt" type="datetime-local" className="form-control" />
                                </InputWrapper>


                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Add subscription</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div className="col-md-4">
                        {userId && <UserDetails id={userId} />}
                    </div> */}
                </div>
            </div>
            <div className="container-fluid mt-5">
                <div className="row">
                    {secondMonthFree.map((item, index) => <div className="col-md-3">
                        {/* <small>{index + 1} </small> */}
                        <UserDetails short={true} id={item} />
                        <SubscriptionInfo course={courseInfo} id={item} />
                    </div>)}
                </div>
                {/* { kjbCwYoGdIT96z1SIkVe8nDMUII2 } */}
            </div>
        </>
    );
};

export default CAFreeOneMonth;

const SubscriptionInfo = ({ id, course }) => {
    const [data, loading, error] = useDocumentData(userSubscriptionsRef.doc(id));
    const updateSub = () => {
        if (course) {
            userSubscriptionsRef.doc(id).set({ app: course }, { merge: true }).then(() => {
                console.log("Updated: ", id, course);
            })
        }
    }
    return (
        <>
            <div className="mt-3 mb-5 text-center">
                <h6 className="">{id} </h6>
                {data ? <div className="text-center">
                    {data.app ? <div>
                        <h6 className="mb-0">Current: {data.app.name} </h6>
                        <p className="mb-0">Start at: {timeStampToDate(data.app.startAt)} </p>
                        <p className="mb-0">End at: {timeStampToDate(data.app.endAt)} </p>
                    </div> : "Not yet"}

                </div> : <div className="text-center">
                    <h6>Not yet</h6>
                </div>}
                <hr />
                {course && <div>
                    <h6 className="mb-0">To be added: {course.name} </h6>
                    <p className="mb-0">Start at: {course.startAt.toDateString()} </p>
                    <p className="mb-0">End at: {course.endAt.toDateString()} </p>
                </div>}
                <button onClick={updateSub} className="btn mt-3 btn-sm btn-danger">Update</button>
            </div>
        </>
    )
}