import React, { useEffect, useState } from 'react';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import { subscriptionsCollectionRef } from '../../firebase/fire-app';
import SubscriptionTable from './SubscriptionTable';

const FilterAllSubscriptions = () => {

    const today = new Date();
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 7)

    const [totalAmount, setTotalAmount] = useState(0)
    const [course, setCourse] = useState("all");
    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(today);
    const [fetchedSubs, setFetchedSubs] = useState(null);
    const [subsStatus, setSubsStatus] = useState(false);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState(null);

    const onStartDateChange = (e) => {
        const changedDate = new Date(e.target.value);
        setStartDate(changedDate);
    }
    const onEndDateChange = (e) => {
        const changedDate = new Date(e.target.value);
        setEndDate(changedDate)
    }
    const resetDates = () => {
        // setStartDate(yesterday);
        setEndDate(today);
    }
    const [susmita, setSusmita] = useState(1);
    const courseCodes = ["app", "PH01", "CH01", "BIO01", "BIO02"];
    const onTimeSpanChange = (e) => {
        const days = e.target.value;
        const now = new Date(today)
        now.setDate(now.getDate() - days)
        setStartDate(now)

    }
    useEffect(() => {
        const fetchSubscriptions = async () => {
            let subscriptionQueries;
            if (startDate && endDate) {
                subscriptionQueries = await subscriptionsCollectionRef.where('date', '>=', startDate).where('date', '<=', endDate).orderBy('date', "desc").get();
            }
            if (subscriptionQueries) {
                setFetchedSubs(
                    subscriptionQueries.docs.map((doc) => {
                        return { id: doc.id, ...doc.data() }
                    }))
            }
        };
        fetchSubscriptions();

    }, [startDate, endDate])
    useEffect(() => {

        if (subsStatus) {
            const fetchSubscriptions = async () => {
                let subscriptionQueries;
                if (startDate && endDate) {
                    subscriptionQueries = await subscriptionsCollectionRef.where('course.endAt', '>=', today).orderBy('date', "desc").get();
                }
                if (subscriptionQueries) {
                    setFetchedSubs(
                        subscriptionQueries.docs.map((doc) => {
                            return { id: doc.id, ...doc.data() }
                        }))
                }
            };
            fetchSubscriptions();
        }
    }, [subsStatus])
    useEffect(() => {
        if (filteredSubscriptions) {
            if (filteredSubscriptions.length > 0) {
                const totalAmount = filteredSubscriptions.reduce((a, b) => {
                    if (b && a) {
                        if (a.amount && b.amount) {
                            return { amount: a.amount + b.amount }
                        }
                    }

                })
                setTotalAmount(totalAmount.amount);
            } else {
                setTotalAmount(0)
            }
        }
    }, [filteredSubscriptions])
    const onCourseChange = (e) => {
        const newCourse = e.target.value;
        setCourse(newCourse);
    }
    useEffect(() => {

        if (fetchedSubs && fetchedSubs.length > 0) {
            if (course === "all") {
                const zeroRemovedSubs = fetchedSubs.filter((sub) => sub.amount !== 0);

                setFilteredSubscriptions([...zeroRemovedSubs])
            } else {
                const filtered = fetchedSubs.filter((sub) => sub.course.courseId === course);
                const zeroRemovedSubs = filtered.filter((sub) => sub.amount !== 0);
                setFilteredSubscriptions([...zeroRemovedSubs])
            }

        }
    }, [fetchedSubs, course])
    const onSubscriptionStatusChange = (e) => {
        const stat = e.target.value;
        if (stat === "false") {
            setSubsStatus(false)
        } else (
            setSubsStatus(true)
        )
    }
    return (
        <>
            <div className="container py-3">
                <div className="row d-flex align-items-center">


                    <InputWrapper col="4" label="Start Date">
                        <input className='form-control' type="datetime-local" onChange={onStartDateChange} name="startDate" id="startDate" />
                    </InputWrapper>

                    <InputWrapper col="4" label="End Date">
                        <input className='form-control' type="datetime-local" onChange={onEndDateChange} name="endDate" id="endDate" />
                    </InputWrapper>
                    <InputWrapper col="4" label="Duration">
                        <select onChange={onTimeSpanChange} name="timeSpan" className="custom-select">
                            {/* <option value="1">Last 2 days</option> */}
                            {/* <option value="3">Last 3 days</option> */}
                            <option value="7">Last 7 days</option>
                            <option value="15">Last 15 days</option>
                            <option value="30">Last 30 days</option>
                            <option value="60">Last 2 months</option>
                            <option value="90">Last 3 months</option>
                            <option value="120">Last 4 months</option>
                            <option value="150">Last 5 months</option>
                            <option value="180">Last 6 months</option>
                            {/* <option value="210">Last 7 months</option>
                            <option value="240">Last 8 months</option>
                            <option value="270">Last 9 months</option>
                            <option value="300">Last 10 months</option>
                            <option value="330">Last 11 months</option>
                            <option value="360">Last 12 months</option> */}
                        </select>
                    </InputWrapper>
                    <InputWrapper col="4" label="Course">
                        <select onChange={onCourseChange} name="timeSpan" className="custom-select">
                            <option value="all">All</option>
                            {courseCodes.map(item => <option value={item}>{item}</option>)}
                        </select>
                    </InputWrapper>
                    <InputWrapper col="4" label="Active Subscription">
                        <select onChange={onSubscriptionStatusChange} name="status" className="custom-select">
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                    </InputWrapper>
                    <div className="col-12 text-center">
                        <p>Course:  <span className="font-bold">{course}</span> | Start Date: <span className="font-bold"> {startDate && startDate.toDateString()}</span> | End Date: <span className="font-bold">{endDate && endDate.toDateString()}</span> | Status: {subsStatus ? <span className='btn btn-danger btn-sm'>Active</span> : "all"}</p>
                        <p>
                            Total subscriptions: <span className="font-bold">{filteredSubscriptions && filteredSubscriptions.length > 0 && filteredSubscriptions.length}</span> |
                            Total amount: <span className="font-bold">{Math.round(totalAmount)}</span>

                        </p>
                    </div>
                </div>
            </div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        {filteredSubscriptions && filteredSubscriptions.length > 0 && <SubscriptionTable data={filteredSubscriptions} />}
                    </div>
                </div>
            </section>
        </>
    );
};

export default FilterAllSubscriptions;