import firebase from "firebase";
import "firebase/auth";
import firebaseConfig from "../firebase-config";

const fireApp = firebase.initializeApp(firebaseConfig);
// fireApp
//     .firestore()
//     .enablePersistence()
//     .catch((err) => {
//         if (err.code === "failed-precondition") {
//             console.log(err);
//         } else console.log(err);
//     });

export default fireApp;
export const fireStoreDB = fireApp.firestore();
export const fireDatabase = fireApp.database();

export const userInfoCollectionRef = fireStoreDB
    .collection("metadata")
    .doc("userMetadata")
    .collection("userInfo");

export const promoCollectionRef = fireStoreDB
    .collection("Payment")
    .doc("Discount")
    .collection("PromoCodes");

export const allPaymentsRef = fireStoreDB
.collection("Payment")
.doc("Payment")
.collection("AllPayments");

export const userPaymentsRef = fireStoreDB
.collection("Payment")
.doc("Payment")
.collection("Users");

export const userSubscriptionsRef = fireStoreDB
.collection("Payment")
.doc("Subscription")
.collection("Users");

export const commissionWithdrawRef = fireStoreDB
.collection("Payment")
.doc("CommissionWithdraw")
.collection("commissionWithdraws");

export const subscriptionsCollectionRef = fireStoreDB
.collection("Payment")
.doc("Subscription")
.collection("AllSubscriptions");

export const salespersonCollectionRef = fireStoreDB.collection("Payment")
.doc("SalesPersons").collection("SalesPersons");

export const paymentWithdrawCollectionRef = fireStoreDB.collection("Payment")
.doc("PaymentWithdraw").collection("paymentWithdraws");

export const serverTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
