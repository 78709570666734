import { useState } from "react";
import { Table } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

import UserShortInfo from '../../Components/UserDetails/UserShortInfo';
import { allPaymentsRef } from "../../firebase/fire-app";
import { customDateFormat } from '../../utilities/CustomModules';


const PaymentsTable = ({ data }) => {

    return (
        <>
            <section className="py-4">
                <div className="container">
                    <Table responsive bordered hover variant="primary">
                        <thead>
                            <tr className="">
                                <th>#</th>
                                <th>Date</th>
                                <th>User</th>
                                <th>Amount</th>
                                <th>Approver</th>
                                <th>Method</th>
                                <th>TrxID</th>
                                <th>UserID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, index) => <SubscriptionCard key={item.id} data={item} serial={index} />)}
                            {data && data.length == 0 && <td className="font-weight-bold h5 ml-4 text-center text-danger" >No sale yet.</td>}
                            {/* <td>  <button onClick={()=> console.log(data)} className="btn btn-dark">Log</button> </td> */}
                        </tbody>
                    </Table>

                </div>
            </section>
        </>
    );
};

export default PaymentsTable;

const SubscriptionCard = ({ data, serial }) => {
    const [property, setProperty] = useState(null);

    // const updateSpecialVoucher =()=>{
    //     allPaymentsRef.doc(data.id).update({ 
    //         specialVoucher: true
    //     }).then(()=> {
    //         console.log("Updated");
    //     })
    // }
    // const deleteData=()=> {
    //     allPaymentsRef.doc(data.id).delete().then(()=>{
    //         console.log("Deleted");
    //     })
    // }

    return (
        <>
            <tr className="" >
                <td>{serial + 1} </td>
                <td>{customDateFormat(data.date)}</td>
                <td><UserShortInfo setProperty={setProperty} uid={data.userId} /> </td>
                {/* <td> <button onClick={()=> console.log(data)} className="btn btn-sm btn-danger">Log</button> 
                <button onClick={()=> deleteData()} className="btn btn-dark btn-sm">Update</button></td> */}
                <td>{data.amount} </td>
                <td>{data.salesPersonId && <UserShortInfo setProperty={setProperty} uid={data.salesPersonId} />} </td>
                <td>{data.paymentMethod} </td>
                <td>{data.transId} </td>
                <td>
                    <CopyToClipboard text={data.userId}>
                        <button className="btn btn-sm btn-danger">Copy UserId </button></CopyToClipboard>
                </td>
            </tr>
        </>
    )
}