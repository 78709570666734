import { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import InputWrapper from "../../Components/InputWrapper/InputWrapper";
import SpanBold from "../../Components/SpanBold/SpanBold";
import UserDetails from "../../Components/UserDetails/UserDetails";
import { salespersonCollectionRef } from "../../firebase/fire-app";
import { customDateFormat } from "../../utilities/CustomModules";

const SalespersonsList = () => {
    const [userName, setUserName] = useState(null);
    const [salespersonDetails, setSalespersonDetails] = useState(null)
    const [count, setCount] = useState(15)
    let userInfoCollectionRef = salespersonCollectionRef.orderBy("currentBalance", "desc");
    // useEffect(()=>{
    //     salespersonCollectionRef.orderBy("currentBalance", "desc").get().then(()=>{
    //         console.log("sdfs");
    //     })
    // },[])

    // useEffect(()=>{
    //     // setSalespersonDetails(null);
    //     if (userName) {
    //         salespersonCollectionRef.doc(userName).onSnapshot((doc)=>{
    //             if(doc.exists){
    //                 setSalespersonDetails({id: doc.id, ...doc.data()})
    //             }
    //         })
    //     }
    // },[userName])
    
    const [searchSalespersons, searchLoading, searchErr] = useCollectionData(userInfoCollectionRef, { idField: "id" })
    const onSearchChanged = (e) => {
        let nm = e.target.value;
        setUserName(nm)
    }
    const findSalesperson =()=>{
        if (userName) {
            salespersonCollectionRef.doc(userName).onSnapshot((doc)=>{
                if(doc.exists){
                    setSalespersonDetails({id: doc.id, ...doc.data()})
                }
            })
        }else{
            setSalespersonDetails(null)
        }
    }


    document.title = userName ? userName : "Salespersons";
    return (
        <>
            <div className="jumbotron text-center">
                {/* <h2 className="text-center">{userName} </h2> */}
                {/* <button onClick={() => console.log(searchUsers)} className="btn btn-danger">Log users</button> */}
                <div className="container">
                    <div className="row">
                        <InputWrapper label="UserId" col="6" addClass=" offset-md-2">
                            <input onChange={onSearchChanged} placeholder="search here.." type="text" className="form-control" />
                        </InputWrapper>
                        <div className="col-md-2">
                            <button onClick={()=> findSalesperson()} className="btn btn-danger btn-sm btn-block">Find</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {salespersonDetails && <SalespersonCard data={salespersonDetails}/> }
                </div>
                <hr/>
            </div>
            <div className="container">
                <div className="row">
                    {!salespersonDetails && searchSalespersons && searchSalespersons.map((item) => (
                        <SalespersonCard data={item} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default SalespersonsList;

const SalespersonCard = ({ data }) => {
    return (
        <div className="col-md-6 mb-3">
            <div className="card">
                <div className="row">
                    <div className="col-md-6">
                        {data.id && <UserDetails id={data.id} />}
                    </div>
                    <div className="col-md-4">
                        <SpanBold title="Current balance" text={data.currentBalance} />
                        <SpanBold title="Total amount" text={data.totalAmount} />
                        <SpanBold title="Total withdraw" text={data.totalWithdraw} />
                        {data.lastWithdraw &&<SpanBold title="Last withdraw" text={customDateFormat(data.lastWithdraw)} />}
                        <button onClick={() => console.log(data)} className="d-none btn btn-dark">Log</button>
                    <Link to={`/withdraw/salesperson/${data.id}`} className="btn btn-warning btn-sm">Withdraws</Link>
                    </div>

                </div>
            </div>
        </div>)
}