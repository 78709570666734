import React, { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { fireStoreDB, userPaymentsRef } from '../../firebase/fire-app';
import SectionSpinner from '../Spinner/SectionSpinner';
import "./UserDetails.scss"

const userInfoCollectionRef = fireStoreDB
    .collection("metadata")
    .doc("userMetadata")
    .collection("userInfo");
const UserDetails = ({ id, getUser, title, short }) => {
    // const [loading, setLoading] = useState(true);
    // const [user, setUser] = useState(null);
    // const [error, setError] = useState(false);
    const [user, loading, error] = useDocumentData(userInfoCollectionRef.doc(id), { idField: "id" });
    const [balance, balLoading, balError] = useDocumentData(userPaymentsRef.doc(id), { idField: "id" });

    // useEffect(() => {
    //     setLoading(true);
    //     setUser(null)
    //     userInfoCollectionRef.doc(id).onSnapshot((doc) => {
    //         setUser({ id: doc.id, ...doc.data() });
    //         setLoading(false)
    //     })
    // }
    //     , [id])
    if (getUser) {
        if (user) {
            getUser(user);
        }
    }
    return (
        <>
            {loading && <SectionSpinner />}
            {!loading && user && <div className="text-center custom-card py-4 bg-primary">
                {title && <h5 className="text-white mb-3">{title} </h5>}
                <img className="avatar rounded-square" src={user.photoUrl} alt="" />
                <h5>{user.name} </h5>
                {short ? "": <div>
                    <p>{user.email} </p>
                    <p>{user.phone} </p>
                    <p><strong>User Type: </strong> {user.userType} </p>
                    <p><strong>Balance: </strong> {balance && balance.amount} </p>

                </div>}

                {/* <p>updated by: {user.typeUpdatedBy} </p> */}
            </div>
            }

        </>
    );
};

export default UserDetails;