import React from 'react';
import { Document, Page, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4',
        width: '100%',
        padding: '40px'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    tableCell:{
        paddingLeft: "10px"
    },
    document:{
        width: "100%",
    }
});

const NewInstallationTable = ({newUsers}) => {
    console.log(newUsers)
    return (
        <>

            <Document style={styles.document}>
                <Page size="A4" style={styles.page}>
                    <Table
                        data={newUsers}
                    >

                        <TableHeader>
                            <TableCell style={styles.tableCell}>
                                Name
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                Phone
                            </TableCell>
                          
                            <TableCell style={styles.tableCell}>
                                District
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                Level
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                UID
                            </TableCell>
                      
                        </TableHeader>
                        <TableBody>
                            <DataTableCell style={styles.tableCell} getContent={(r) => r.name} />
                            <DataTableCell style={styles.tableCell} getContent={(r) => r.phone} />
                            <DataTableCell style={styles.tableCell} getContent={(r) => r.district} />
                            <DataTableCell style={styles.tableCell} getContent={(r) => r.level} />
                            <DataTableCell style={styles.tableCell} getContent={(r) => r.uid} />
                        </TableBody>


                    </Table>
                </Page>
            </Document>

        </>
    );
};

export default NewInstallationTable;
