import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonGroup } from 'react-bootstrap';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import { userInfoCollectionRef } from '../../firebase/fire-app';
import { useCollectionData } from 'react-firebase-hooks/firestore';


const SearchUser = () => {
    const { register, handleSubmit } = useForm();
    const [searchValue, setSearchValue] = useState("13.162.swajan@gmail.com");
    const [searchBy, setSearchBy] = useState("userId");
    const [userQuery, setUserQuery] = useState(userInfoCollectionRef.limit(1));
    const onSearchParamChange = (value) => {
        setSearchBy(value)
    }
    const [oneUser, setOneUser] = useState(null);
const [user, loading, error] = useCollectionData(userInfoCollectionRef.where("email", "==", searchValue ))

    const onSearchClicked = (data) => {
        setSearchValue(data.searchParam)
    }
    useEffect(()=>{
        if(user){
            if(user.length>0){
                setOneUser(user[0])
            }
        }
    },[user])
    return (
        <>
            <div className="row">
                <div className="col-md-5">
                    <ButtonGroup aria-label="Type Selection">
                        <Button onClick={() => onSearchParamChange("userId")} variant={searchBy == "userId" ? "danger" : "dark"} >User ID</Button>
                        <Button onClick={() => onSearchParamChange("name")} variant={searchBy == "name" ? "danger" : "dark"} ></Button>
                        <Button onClick={() => onSearchParamChange("phone")} variant={searchBy == "phone" ? "danger" : "dark"} >Phone</Button>
                        <Button onClick={() => onSearchParamChange("email")} variant={searchBy == "email" ? "danger" : "dark"} >Email</Button>
                    </ButtonGroup>
                    <form onSubmit={handleSubmit(onSearchClicked)} className="row mt-4">
                        <InputWrapper col="12" label={searchBy} >
                            <input name="searchParam" ref={register({ required: true })} type="text" className="form-control" />
                        </InputWrapper>
                        <div className="col-12 text-left">
                            <button type="submit" className="btn btn-danger btn-sm">Search</button>
                        </div>
                    </form>
                </div>
                {searchValue && <div className="col-md-7">
                    <h5 className="text-center">Searching by {searchValue}, ({searchBy}) </h5>
                    <div className="text-center">
                        <button onClick={()=> console.log(user)} className="btn btn-primary mt-5">Log</button>
                    </div>
                </div>}
                {oneUser && <p>{oneUser.name} | {oneUser.uid} </p>}
            </div>
        </>
    );
};

export default SearchUser;