import { useEffect, useState } from 'react';
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import UserDetails from '../../Components/UserDetails/UserDetails';
import { fireStoreDB } from '../../firebase/fire-app';
import PromoCodesContainer from './PromoCodesContainer';


const promoCollectionRef = fireStoreDB.collection("Payment").doc("Discount").collection("PromoCodes");

const ViewPromoCodes = () => {
    const [showLoad, setShowLoad] = useState(false);
    const { register, handleSubmit } = useForm();
    const [editMode, setEditMode] = useState(false);
    const [editPromo, setEditPromo] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userCommission, setUserCommission] = useState(20);
    const [discountRate, setDiscountRate] = useState(16.67);
    const [values, loading, error] = useCollectionData(promoCollectionRef.orderBy("createdAt", "desc"), { idField: "id" });

    const customExpiry = new Date(new Date().getTime() + (180 * 24 * 60 * 60 * 1000));

    const [promoId, setPromoId] = useState(null);
    const [promoExists, setPromoExists] = useState(false);
    const [promoChecking, setPromoChecking] = useState(false);


    const onPromoCodeAdded = (data, e) => {
        
        data.discountRate = Number(discountRate)
        data.userCommission = Number(userCommission)
        data.expiryDate = customExpiry;
        data.createdAt = new Date();
        const pCode = data.promoCode;
        delete data.promoCode
        promoCollectionRef.doc(pCode).set(data).then(res => {
            e.target.reset();
        })

    }
    const onPromoCodeEdited = (data, e) => {
        const pCode = editPromo.id;
        data.discountRate = parseFloat(data.discountRate)
        data.userCommission = parseFloat(data.userCommission)
        data.createdAt = new Date();
        // delete data.promoCode
        if(!data.expiryDate){
            data.expiryDate = customExpiry;
        }else{
            data.expiryDate = new Date(data.expiryDate)
        }
        promoCollectionRef.doc(editPromo.id).set(data, {merge: true}).then(res => {
            e.target.reset();
            setEditMode(false);
            setEditPromo({...data, id: pCode});
            setUserId(null)
        })
    }
    // checking duplicate promo
    useEffect(() => {
        if (promoId) {
            setPromoChecking(true);
            promoCollectionRef.doc(promoId).onSnapshot((doc) => {
                if (doc.exists) {
                    console.log(doc.id);
                    setPromoExists(true)
                    setPromoChecking(false);
                } else {
                    setPromoExists(false)
                    setPromoChecking(false)
                }
            })
        }

    }, [promoId])
    // checking existing promo holder
    useEffect(()=>{
        setPromoChecking(true);

        promoCollectionRef.where("userId", "==", userId).onSnapshot((querySnapshot)=>{
            if(querySnapshot.docs.length > 0){
                console.log(querySnapshot.docs[0].id);
                setPromoExists(true)
                setPromoChecking(false);
            }else{
                setPromoExists(false);
                setPromoChecking(false)
            }
        })
    },[userId])

    const onUserIdChanged = (e) => {
        setUserId(e.target.value)
    }
    const onPromoCodeChanged = (e) => {
        const promoCodeTrim =e.target.value.trim()
        console.log(promoCodeTrim);
        // setPromoId(promoCodeTrim)
    }
    return (
        <>
        {/* <button onClick={()=> console.log(editMode)} className="btn-primary">log</button> */}
    {!editMode &&<Container>
                <h4 className="text-primary text-center mt-3 ">Add New Promo Code</h4>
                <p className="text-center mb-4">User Commission:{userCommission}, Discount Rate: {discountRate} </p>
                <form className="mt-0 row d-flex align-items-center" onSubmit={handleSubmit(onPromoCodeAdded)}>
                    <div className="col-md-8">
                        <Row>
                            <InputWrapper col="7" label="UserId">
                                <input onChange={onUserIdChanged} ref={register({ required: true })} name="userId" type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="5" label="Promo Code">
                                <input ref={register()} onChange={onPromoCodeChanged} name="promoCode" type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="6" label="User commission (%)">
                                <input onChange={(e) => setUserCommission(e.target.value)} placeholder={userCommission} step="0.01" name="userCommission" type="number" className="form-control" />
                            </InputWrapper>
                            
                            <InputWrapper col="6" label="Student Discount (%)">
                                <input onChange={(e) => setDiscountRate(e.target.value)} placeholder={discountRate} step="0.01" name="discountRate" type="number" className="form-control" />
                            </InputWrapper>
                
                            <div className="col-md-6 mt-4 text-center text-md-left mb-4">
                                {promoChecking && <Button className="btn btn-danger" disabled>Checking <Spinner variant="white" size="sm" animation="border" /> </Button>}
                                {(promoExists && !promoChecking) ? <Button className="btn btn-danger" disabled>Promo Code exists </Button> : (!promoChecking) && <Button type="submit" className="btn btn-primary">Add Promo Code</Button>
                                }                            
                                </div>
                        </Row>
                    </div>
                    <div className="col-md-4">
                        {userId && <UserDetails id={userId} />}
                    </div>
                </form>
            </Container>}
            {/* <div className="container pt-4">
                <hr />
            </div> */}
            {editMode && <Container>
                <h3 className="text-primary text-center my-4">Edit promo: {editPromo.id}</h3>
                <form className="mt-0 row d-flex align-items-center" onSubmit={handleSubmit(onPromoCodeEdited)}>
                    <div className="col-md-8">
                        <Row>
                            <InputWrapper col="7" label="UserId">
                                <input defaultValue={editPromo.userId} onChange={onUserIdChanged} ref={register({ required: true })} name="userId" type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="5" label="User commission (%)">
                                <input defaultValue={editPromo.userCommission} ref={register({ required: true })} name="userCommission" type="number" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="7" label="Promo Code">
                                <input disabled defaultValue={editPromo.id}  type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="5" label="Student Discount (%)">
                                <input defaultValue={editPromo.discountRate} ref={register({ required: true })} name="discountRate" type="number" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="5" label="Expiry:">
                                <input disabled defaultValue={new Date(editPromo.expiryDate.seconds*1000).toDateString()}  type="text" className="form-control" />
                            </InputWrapper>
                            <InputWrapper col="7" label="Change expiry">
                                <input   ref={register()} name="expiryDate" type="datetime-local" className="form-control" />
                            </InputWrapper>
                            {/* <InputWrapper col="7" label="Expiry">
                                <input ref={register({ required: true })} name="expiryDate" type="datetime-local" className="form-control" />
                            </InputWrapper> */}

                            <div className="col-md-6">
                                <Button type="submit" className="btn btn-primary">Update Promo Code</Button>
                            </div>
                        </Row>
                    </div>
                    <div className="col-md-4">
                        {userId && <UserDetails id={userId} />}
                    </div>
                    <div className=" pt-4">
                        <hr />
                    </div>
                </form>
            </Container>}

            <div className="mt-4 bg-light">

                {loading && <SectionSpinner />}
                {values && <PromoCodesContainer setEditMode={setEditMode} setUserId={setUserId} setEditPromo={setEditPromo} editPromo={editPromo} words={values} />}
                {showLoad && <SectionSpinner />}

            </div>
        </>
    );
};

export default ViewPromoCodes;

{/* <Container>
    <h3 className="text-primary text-center my-4">Add New Promo Code</h3>
    <form className="mt-0 row d-flex align-items-center" onSubmit={handleSubmit(onPromoCodeAdded)}>
        <div className="col-md-8">
            <Row>
                <InputWrapper col="7" label="UserId">
                    <input defaultValue={editPromo.userId} onChange={onUserIdChanged} ref={register({ required: true })} name="userId" type="text" className="form-control" />
                </InputWrapper>
                <InputWrapper col="5" label="User commission (%)">
                    <input defaultValue={editPromo.userCommission} ref={register({ required: true })} name="userCommission" type="number" className="form-control" />
                </InputWrapper>
                <InputWrapper col="7" label="Promo Code">
                    <input defaultValue={editPromo.id} ref={register({ required: true })} name="promoCode" type="text" className="form-control" />
                </InputWrapper>
                <InputWrapper col="5" label="Student Discount (%)">
                    <input defaultValue={editPromo.discountRate} ref={register({ required: true })} name="discountRate" type="number" className="form-control" />
                </InputWrapper>
                <InputWrapper col="7" label="Expiry">
                    <input ref={register({ required: true })} name="expiryDate" type="datetime-local" className="form-control" />
                </InputWrapper>

                <div className="col-md-6">
                    <button type="submit" className="btn btn-primary">Add Promo Code</button>
                </div>
            </Row>
        </div>
        <div className="col-md-4">
            {userId && <UserDetails id={userId} />}
        </div>
    </form>
</Container> */}