import { Breadcrumb } from 'antd';
import React from 'react';

const AdminSubLayout = ({children, crumbs}) => {
    return (
        <>
            {/* <Breadcrumb style={{ margin: '16px 0'}}>
                {crumbs && crumbs.map(item=> <Breadcrumb.Item>{item} </Breadcrumb.Item> )}
                
            </Breadcrumb> */}
            <div className="site-layout-background" style={{ marginTop: 16, padding: 16, minHeight: 360 }}>
              {children}
            </div>
        </>
    );
};

export default AdminSubLayout;