import React, { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { fireStoreDB } from '../../firebase/fire-app';


const usersCollectionRef = fireStoreDB.collection("metadata").doc("userMetadata").collection("userInfo").orderBy("createdAt").limit(20);
const usersCollectionRefNew = fireStoreDB.collection("metadata").doc("userMetadata").collection("userInfo");
const UpdateUsers = () => {
    const [users, loading, error] = useCollectionData(usersCollectionRef, { idField: "id" });
    return (
        <>
            <div className="container mt-3">
                <div className="row">

                    {users && users.map(item => <UserProfile user={item} />)}

                </div>
            </div>
        </>
    );
};

export default UpdateUsers;

const UserProfile = ({ user }) => {
    const [createdAt, setCreatedAt] = useState(null);
    const created = new Date(user.createdAt.seconds * 1000)
    const d = `${created.getDate()}/${created.getMonth() + 1}`
    const onUpdate = () => {
        usersCollectionRefNew.doc(user.id).update({
            createdAt: new Date()
        })
    }
    return (
        <div className="col-md-3">
            <div className="text-center custom-card py-4 bg-primary mb-3">
                <img className="avatar rounded-square" src={user.photoUrl} alt="" />
                <h5>{user.name} </h5>
                <p>{user.email} </p>
                <p>{user.phone} </p>
                <p><strong>User Type: </strong> {user.userType} </p>
                <p>{d} </p>
                <button onClick={onUpdate} className="btn btn-danger">Update</button>
            </div>
        </div>

    )
}