import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import UserDetails from '../../Components/UserDetails/UserDetails';
import { fireStoreDB, salespersonCollectionRef, serverTimestamp } from '../../firebase/fire-app';
import { useAuth } from "../../Hooks/useAuth";
import firebase from 'firebase'

const subscriptionDocRef = fireStoreDB.collection("Payment").doc("Subscription");
const salesPersonRef = fireStoreDB.collection("Payment").doc("SalesPersons").collection("SalesPersons");
const userInfoCollectionRef = fireStoreDB
    .collection("metadata")
    .doc("userMetadata")
    .collection("userInfo");
const AddSubscription = () => {
    const { register, handleSubmit } = useForm();
    const [userId, setUserId] = useState(null);
    const {currentUser} = useAuth()

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 55);

    const courses = ["app", "PH01", "CH01", "MT01", "BIO01", "BIO02", "questionBank"];
    const courseNames = ["App", "Physics-I", "Chemistry-I", "Math-I", "Biology-I", "Biology-II", "Question-Bank"];
    const onPaymentAdded = (data, e) => {
        data.date = yesterday;
        data.amount = Number(data.amount);
        data.isRenew = (data.isRenew == "true")
        data.course.startAt = new Date(data.course.startAt);
        data.course.endAt = new Date(data.course.endAt);
        data.salesperson = currentUser.id;
        const courseId = data.course.courseId;
        subscriptionDocRef.collection("AllSubscriptions").add(data).then(() => {
            console.log("done");
            // if (data.course.courseId == "app") {
            //     subscriptionDocRef.collection("Users").doc(data.userId).set({ app: data.course }, { merge: true }).then(() => {
            //         e.target.reset();
            //     })
            // } else {
            //     subscriptionDocRef.collection("Users").doc(data.userId).set({ subjects: { [courseId]: data.course } }, { merge: true }).then(() => {
            //         e.target.reset()
            //     })
            // }

        })

        // userInfoCollectionRef.doc(data.userId).update({
        //     userType: data.userType
        // })
        const salespersonId = currentUser.id;
        // const expiryDate = new Date(new Date().getTime() + (300 * 24 * 60 * 60 * 1000))
        salespersonCollectionRef.doc(salespersonId).update({
            totalAmount: firebase.firestore.FieldValue.increment(data.amount),
            currentBalance: firebase.firestore.FieldValue.increment(data.amount)
        })
    }
    const onUserIdChanged = (e) => {
        setUserId(e.target.value)
    }
    const userTypes = ["Admin", "Campus Ambassador", "Free User", "Support Member", "Salesperson", "Student"];

    return (
        <>
            <div>
                <div className="jumbotron text-center">
                    <h3 className="text-primary">Add Subscription</h3>
                </div>
            </div>
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-8">
                        <form onSubmit={handleSubmit(onPaymentAdded)}>
                            <div className="row">
                                <InputWrapper col="7" label="UserId">
                                    <input onChange={onUserIdChanged} ref={register({ required: true })} name="userId" type="text" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="5" label="Promo Code">
                                    <input ref={register({  })} name="promoCode" type="text" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="4" label="Amount">
                                    <input defaultValue={300} ref={register({ required: true })} step="0.01" name="amount" type="text" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col={4} label="Renew:">
                                    <select ref={register({ required: true })} name="isRenew" className="custom-select">
                                        <option value={false} selected>Choose</option>
                                        <option value={false}  >False</option>
                                        <option value={true}>True</option>
                                    </select>
                                </InputWrapper>
                                <InputWrapper col="4" label="Description">
                                    <input ref={register({  })} name="description" type="text" className="form-control" />
                                </InputWrapper>
                                {/* course details */}
                                <InputWrapper col="6" addClass="mt-3" label="Course name">
                                    <select ref={register({ required: true })} name="course.name" className="custom-select">
                                        <option value={false} selected>Choose</option>
                                        {courseNames.map(item => <option value={item}>{item} </option>)}
                                    </select>
                                </InputWrapper>
                                <InputWrapper col="6" addClass="mt-3" label="Course Id">
                                    <select ref={register({ required: true })} name="course.courseId" className="custom-select">
                                        <option value={false} selected>Choose</option>
                                        {courses.map(item => <option value={item}>{item} </option>)}
                                    </select>
                                </InputWrapper>
                                <InputWrapper col="6" addClass="mt-3" label="Start at">
                                    <input ref={register({ required: true })} name="course.startAt" type="datetime-local" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="6" addClass="mt-3" label="End at">
                                    <input ref={register({ required: true })} name="course.endAt" type="datetime-local" className="form-control" />
                                </InputWrapper>


                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Add subscription</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                        {userId && <UserDetails id={userId} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddSubscription;