import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminLayout from "../Components/AdminLayout/AdminLayout";
import AdminSubLayout from "../Components/AdminLayout/AdminSubLayout";
// import Header from "../Components/Header/Header";
import { PrivateRoute } from "../Hooks/useAuth";
import PageUpdateUser from "./AddSalesperson/PageUpdateUser";
// import AddSalesperson from "./AddSalesperson/PageUpdateUser";
import SalespersonsList from "./AddSalesperson/SalespersonsList";
import ActiveSubscriptions from "./AddSubscription/ActiveSubscriptions";
import AddSubscription from "./AddSubscription/AddSubscription";
import AllSubscriptions from "./AddSubscription/AllSubscriptions";
import CAFreeOneMonth from "./AddSubscription/CAFreeOneMonth";
import FilterAllSubscriptions from "./AddSubscription/FilterAllSubscriptions";
import AllUsersView from "./AllUsersView/AllUsersView";
import NewInstallation from "./AllUsersView/NewInstallation";
import HomeDashboard from "./HomeDashboard/HomeDashboard";
import ViewLogin from "./Login/ViewLogin";
import ViewPromoCodes from "./PromoCodes/ViewPromoCodes";
import ViewTrackPromos from "./PromoCodes/ViewTrackPromos";
import SpecialVoucher from "./SpecialVoucher/SpecialVoucher";
import UpdateUsers from "./UpdateUsers/UpdateUsers";
import ViewPaymentApproval from "./ViewPaymentApproval/ViewPaymentApproval";
import ViewAllPayments from "./ViewPayments/ViewAllPayments";
import ViewVoucherCodes from "./VoucherCodes/ViewVoucherCodes";
import PromoCodeWithdrawDetails from "./Withdraw/PromocodeWithdrawDetails";
import SalespersonWithdrawDetails from "./Withdraw/SalespersonWithdrawDetails";
import WithdrawFromAffiliates from "./Withdraw/WithdrawFromAffiliates";
import WithdrawFromSalesperson from "./Withdraw/WithdrawFromSalesperson";

const Main = () => {
    return (
        <>
            <Router>
                <AdminLayout>
                    {/* <Header /> */}
                    <Switch>
                        <PrivateRoute exact path="/all-users">
                            <AdminSubLayout crumbs={["Home"]}>
                                <AllUsersView />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/update-user">
                            <AdminSubLayout crumbs={["Home"]}>
                                <PageUpdateUser />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/add-subscription">
                            <AdminSubLayout crumbs={["Home"]}>
                                <AddSubscription />
                            </AdminSubLayout>
                        </PrivateRoute>
                        {/* Note */}
                        <PrivateRoute exact path="/all-subscriptions">
                            <AdminSubLayout crumbs={["Home"]}>
                                <FilterAllSubscriptions />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/new-installation">
                            <AdminSubLayout crumbs={["Home"]}>
                                <NewInstallation />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/active-subscriptions">
                            <AdminSubLayout crumbs={["Home"]}>
                                <ActiveSubscriptions />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/all-payments">
                            <AdminSubLayout crumbs={["Home"]}>
                                <ViewAllPayments />
                            </AdminSubLayout>
                        </PrivateRoute>
                        {/* promos */}
                        <PrivateRoute exact path="/promo-codes">
                            <AdminSubLayout crumbs={["Home"]}>
                                <ViewPromoCodes />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/track-promos">
                            <AdminSubLayout crumbs={["Home"]}>
                                <ViewTrackPromos />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/voucher-codes">
                            <AdminSubLayout crumbs={["Home"]}>
                                <ViewVoucherCodes />
                            </AdminSubLayout>
                        </PrivateRoute>
                        {/* withdraw */}
                        <PrivateRoute exact path="/withdraw/salesperson">
                            <AdminSubLayout crumbs={["Home"]}>
                                <WithdrawFromSalesperson />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/withdraw/affiliates">
                            <AdminSubLayout crumbs={["Home"]}>
                                <WithdrawFromAffiliates />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/withdraw/promo-code/:promoId">
                            <AdminSubLayout crumbs={["Home"]}>
                                <PromoCodeWithdrawDetails />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/withdraw/salesperson/:userId">
                            <AdminSubLayout crumbs={["Home"]}>
                                <SalespersonWithdrawDetails />
                            </AdminSubLayout>
                        </PrivateRoute>

                        {/* salespersons */}
                        <PrivateRoute exact path="/all-salespersons">
                            <AdminSubLayout crumbs={["Home"]}>
                                <SalespersonsList />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/">
                            <AdminSubLayout>
                                <FilterAllSubscriptions />
                                {/* <AllUsersView /> */}
                                {/* <HomeDashboard/> */}
                            </AdminSubLayout>
                        </PrivateRoute>
                        <PrivateRoute exact path="/ca-free">
                            <AdminSubLayout>
                                <CAFreeOneMonth />
                            </AdminSubLayout>
                        </PrivateRoute>
                        <Route exact path="/login">
                            <ViewLogin />
                        </Route>
                        <Route exact path="/special-voucher">
                            <SpecialVoucher />
                        </Route>
                    </Switch>
                </AdminLayout>
            </Router >
        </>
    );
};

export default Main;