import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDocumentData } from "react-firebase-hooks/firestore";
import UserShortInfo from "../../Components/UserDetails/UserShortInfo";
import { subscriptionsCollectionRef, userInfoCollectionRef } from "../../firebase/fire-app";
import { customDateFormat } from '../../utilities/CustomModules';

const ActiveSubsTable = ({ data }) => {
    return (
        <>
            <section className="py-4">
                <div className="container smol-text">
                    <Table responsive bordered hover variant="primary">
                        <thead>
                            <tr className="">
                                {/* <th>Sl.</th>
                                <th>Description</th> */}
                                <th>StartAt</th>
                                <th>EndAt</th>
                                <th>UserId</th>
                                <th>User</th>
                                <th>Contact</th>
                                <th>Email</th>                                
                                <th>District</th>
                                <th>Course</th>
                                <th>Amount</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, index) => <SubscriptionCard key={item.id} data={item} serial={index} />)}
                            {data && data.length == 0 && <td className="font-weight-bold h5 ml-4 text-center text-danger" >No sale yet.</td>}
                            {/* <td>  <button onClick={()=> console.log(data)} className="btn btn-dark">Log</button> </td> */}
                        </tbody>
                    </Table>

                </div>
            </section>
        </>
    );
};

export default ActiveSubsTable;


const SubscriptionCard = ({ data, serial }) => {
    const [user, loading, error] = useDocumentData(userInfoCollectionRef.doc(data.userId))

    const [view, setView] = useState(true);
    const baad = [`iBnmtcTb7mXIbJeD7Fc93M0cBmX2`, `TBCNCLXf8eQ13ewKVlmCdvdg0lY2`, `MHtvQbt6gMWdbeNvluJjsUKm4yD2`, `FQGh6g1Xh5PcxDApYYSiPKffHJC2`, `sQVRKFbPqkfsCHQEaQwhAgQXkZB3`, `qUaWNxtEIOObHw5vkVWJCrpMepD3`, `3JAGxo9aONR7kbYKuDUyv63uFKt2`, `osgl7Zts7TTwPHCVvHpSBYy6XIK2`, `9BgMuZtgmBenaN46NHgDnNIZHmC3`, `V7NdhfqUKmMifuEuTLlH9XM66qK2`, `lFzYjWVez7cpsyIYttNbGCNTIvq2`, `WTJnXzzsQcbA0jgnyhXInJfeEi93`, `1wffkCm2k7gManwt3AuUuAUbuOT2`, `djT45xv9IgXQ7ocZDhBY5OB76563`, `AJT2axMNZSZzEbOfgZ5WzqXP7Av1`]
    useEffect(() => {
        if (data) {
            const has = baad.filter(item => item === data.userId);
            if (has.length > 0) {
                setView(false)
            }
            if(data.hide){
                setView(false)
            }
        }
    }, [data])
const removeUser=(id)=>{
    console.log(id);
    subscriptionsCollectionRef.doc(id).update({
        hide: false
    }).then(()=>{
        setView(false)
    })
}
    return (
        <>
            {view && <tr className="" >
                {/* <td>{serial+1} </td>
                <td>{data.description}</td> */}
                <td> {customDateFormat(data.course.startAt)} </td>
                <td>{customDateFormat(data.course.endAt)}</td>
                <td>{data.userId}</td>
                <td>{user && user.name}</td>
                <td>{user && user.phone} </td>
                <td>{user && user.email} </td>
                <td>{user && user.district} </td>
                <td>{data.course.name} </td>
                <td>{data.amount} </td>
                <td>{data.hide && "hide"} <button onClick={()=> removeUser(data.id)} className="btn btn-sm btn-danger">Delete</button> </td>
            </tr>}
        </>
    )
}