import React from 'react';
import { Table } from 'react-bootstrap';
import {customDateFormat} from "../../utilities/CustomModules"

const CommissionWithdrawTable = ({allData}) => {
    return (
        <>
            <Table responsive bordered hover variant="primary">
                                <thead>
                                    <tr className="">
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Note</th>
                                        <th>Receiver</th>
                                        <th>Processor</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {allData && allData.map((item, index) => <TableRow data={item} serial={index} />)}
                                </tbody>
                            </Table>
        </>
    );
};

export default CommissionWithdrawTable;

const TableRow = ({ data, serial })=>{
    return(
        <>
        <tr className="" >
                <td>{serial + 1} </td>
                <td>{customDateFormat(data.date)}</td>
                {/* <td>10/12/3200</td> */}
                <td>{data.note} </td>
                <td>{data.receiver}</td>
                <td>{data.withdrawBy}</td>
                <td> <span className="text-danger font-weight-bold"> {data.withdrawAmount}</span></td>
            </tr>
        </>
    )
}