import React, { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { subscriptionsCollectionRef, userSubscriptionsRef } from '../../firebase/fire-app';
import ActiveSubsTable from './ActiveSubsTable';
import SubscriptionTable from './SubscriptionTable';

const ActiveSubscriptions = () => {
    const [activeSubs, loading, error] = useCollectionData(subscriptionsCollectionRef.orderBy('course.startAt', 'desc'), {idField: "id"});
    const [allData, setAllData] = useState(null);
    useEffect(()=>{
        subscriptionsCollectionRef.where('course.endAt', '>', new Date()).orderBy('course.endAt').get().then((docs)=>{
            setAllData(
                docs.forEach((doc)=>{
                    return {id: doc.id, ...doc.data()}
                })
            )
        })
    },[])
    // subscriptionsCollectionRef.where('course.endAt', '>', new Date() ).orderBy('course.endAt').get().then((docs)=>{
    //     console.log(docs.length);
    // })
    if(allData){
        console.log(allData);
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    { allData && allData.length>0 && <ActiveSubsTable data={allData}/> }
                </div>
            </div>
        </>
    );
};

export default ActiveSubscriptions;