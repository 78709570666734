import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import UserCardComponent from '../../Components/UserCardComponent/UserCardComponent';
import { userInfoCollectionRef } from '../../firebase/fire-app';

const AllUsersView = () => {
    const [currentType, setCurrentType] = useState("Admin")

    const changeCurrentType = (type) => {
        setCurrentType(type)
    }
    const [allUsers, loading, error] = useCollectionData(userInfoCollectionRef.where("userType", "==", currentType));
    return (
        <>
            <div className="py-3">
                <div className="container-fluid text-center">
                    <ButtonGroup aria-label="Type Selection">
                        <Button onClick={() => changeCurrentType("Admin")} variant={currentType == "Admin"? "danger": "dark"} >Admin</Button>
                        <Button onClick={() => changeCurrentType("Support Member")} variant={currentType == "Support Member"? "danger": "dark"} >Support Member</Button>
                        <Button onClick={() => changeCurrentType("Campus Ambassador")} variant={currentType == "Campus Ambassador"? "danger": "dark"} >Campus Ambassador</Button>
                        <Button onClick={() => changeCurrentType("Free User")} variant={currentType == "Free User"? "danger": "dark"} >Free User</Button>                
                    </ButtonGroup>
                    {currentType && <h4 className="mt-4 text-center">{currentType} : {allUsers && allUsers.length} </h4>}
                </div>
            </div>
            <section>
                <div className="container text-center">
                    
                    {!error && !loading && allUsers && allUsers.map(item => <UserCardComponent data={item} key={item.uid} />)}
                </div>
            </section>
        </>
    );
};

export default AllUsersView;