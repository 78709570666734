import AdminLayout from "./Components/AdminLayout/AdminLayout";
import { AuthContextProvider } from "./Hooks/useAuth";
import Main from "./Views/Main";

function App() {
  return (
    <>
      <AuthContextProvider>
        <Main />
      </AuthContextProvider>
    </>
  );
}

export default App;
