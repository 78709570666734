import { useCollectionData } from "react-firebase-hooks/firestore";
import { allPaymentsRef, userInfoCollectionRef, userPaymentsRef } from "../../firebase/fire-app";
import firebase from "firebase";
import { useEffect, useState } from "react";

const SpecialVoucher = () => {
    const expiryDate = new Date(new Date().getTime() + (300 * 24 * 60 * 60 * 1000));
    // const [users, loading, error] = useCollectionData(userInfoCollectionRef);
    const users = [{ uid: "djT45xv9IgXQ7ocZDhBY5OB76563" }, { uid: "TBCNCLXf8eQ13ewKVlmCdvdg0lY2" }, { uid: "1wffkCm2k7gManwt3AuUuAUbuOT2" }, { uid: "4g5uooJGhKczNYe8JRkrsInZHGs1" }, { uid: "9BgMuZtgmBenaN46NHgDnNIZHmC3" }]
    const addEidSalami = () => {
        if (users) {
            users.forEach(user => {
                const paymentDetails = {
                    paymentMethod: "Eid Salami",
                    amount: 50,
                    date: new Date(),
                    isApproved: true,
                    isPending: false,
                    salesPersonId: "djT45xv9IgXQ7ocZDhBY5OB76563",
                    specialVoucher: true,
                    userId: user.uid,
                    transAccountNo: "Prottoy",
                    transId: "ProttoyEidSalami"
                }
                allPaymentsRef.add(paymentDetails).then(() => {
                    userPaymentsRef.doc(user.uid).set({
                        amount: firebase.firestore.FieldValue.increment(50),
                        expiryDate: expiryDate,
                    }, { merge: true }).then(() => {
                        console.log("Eid salami sent to: ", 'swajan', " !");
                    })
                })
            })
        }
    }
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const [payments, payLoad, payError] = useCollectionData(allPaymentsRef.orderBy("date").limit(1), {idField: "id"});
    // const [payments, setPayments] = useState(null)
    // useEffect(() => {
    //     allPaymentsRef.where("date", "<", yesterday ).orderBy("date").limit(10).get().then((docs)=>{
    //         console.log(yesterday, "found", docs)
    //         docs.forEach((doc) => {
    //             console.log( {...doc.data(), id: doc.id})
    //         })
    //         setPayments(
    //             docs.forEach((doc) => {
    //                 return {...doc.data(), id: doc.id}
    //             })
    //         )


    //     })
    // },[])
const [falseCount, setFalseCount] = useState(0);
const [updateCount, setUpdateCount] = useState(0);
const [specialCount, setSpecialCount] = useState(0);

    const updateAllPayments = () => {
        // console.log(payments)
        let falfal = 0
        let upup = 0
        let spsp =0
        if (payments) {
            payments.forEach(pay => {
                if (pay.specialVoucher === true) {
                    console.log(`Special voucher: ${pay.id}`);
                    spsp = spsp + 1
                    setSpecialCount(spsp)
                } else if (pay.specialVoucher === false) {
                    console.log("eita false");
                    falfal = falfal + 1;
                    setFalseCount(falfal)
                } else {
                    allPaymentsRef.doc(pay.id).update({
                        specialVoucher: false
                    }).then(() => {
                        upup = upup+1;
                        setUpdateCount(upup);
                        console.log("updated false")
                    })
                }
            })
        }
    }
    return (
        <>
            <div className="jumbotron text-center">
                <h2>Special Voucher</h2>
                {/* <button onClick={()=> addEidSalami()} className="btn btn-primary btn-lg mt-4">Send Salami</button> <br /> */}
                <button onClick={()=>console.log(users && users[0].name)} className="btn btn-danger mt-4">Log users</button><br />
                <button onClick={()=>console.log(users && users.length)} className="btn btn-danger mt-4">Log users count</button><br />
            </div>
            {/* <div className="jumbotron mt-5 text-center">
                <h2>Special Voucher  </h2>
                <h3>False: {falseCount}</h3>
                <h3>Updated: {updateCount}</h3>
                <h3>Special: {specialCount}</h3>
                <button onClick={() => updateAllPayments()} className="btn btn-primary btn-lg mt-4">Update payments</button>
                <button onClick={() => console.log(payments && payments.length)} className="btn btn-danger">Log users</button>
            </div> */}

        </>
    );
};

export default SpecialVoucher;