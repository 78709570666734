import React, { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Redirect, Route } from "react-router-dom";
import SectionSpinner from "../Components/Spinner/SectionSpinner";
import fireApp, { fireStoreDB } from "../firebase/fire-app";

export const AuthContext = React.createContext();
const userInfoCollectionRef = fireStoreDB
    .collection("metadata")
    .doc("userMetadata")
    .collection("userInfo");
const salesPersonRef = fireStoreDB.collection("Payment").doc("SalesPersons").collection("SalesPersons");
const salesBalance = {
    currentBalance: 0,
    totalWithdraw: 0,
    totalAmount: 0
}
export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        setPending(true)
        fireApp.auth().onAuthStateChanged((usr) => {
            if (usr) {
                userInfoCollectionRef.doc(usr.uid).onSnapshot((doc) => {
                    const fetchedUser = { id: usr.uid, ...doc.data() };
                    
                    if (fetchedUser.userType === "Admin") {
                        setCurrentUser({ ...fetchedUser })
                        salesPersonRef.doc(fetchedUser.id).get().then((doc) => {
                            if (!doc.exists) {
                                salesPersonRef.doc(fetchedUser.id).set({
                                    currentBalance: 0,
                                    totalWithdraw: 0,
                                    totalAmount: 0
                                })
                            }
                        })
                        setPending(false)
                    }else{
                        setCurrentUser(null)
                    }

                })
            }
            console.log(pending);
            setPending(false);
        });
    }, []);

    if (pending) {
        return <> <SectionSpinner/> </>;
    }

    return (
        <AuthContext.Provider value={{ currentUser, pending, setCurrentUser }}>
            {children}
        </AuthContext.Provider>
    );
};
export const PrivateRoute = ({ children, ...rest }) => {
    const { currentUser, pending } = useContext(AuthContext);
    console.log(pending);
    if(pending){
        console.log("pp", pending);
        return <SectionSpinner/>
    }else{
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (currentUser) ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location },
                            }}
                        />
                    )
            }
        />
    );}
};
export const useAuth = () => useContext(AuthContext);
