import React, { useEffect } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router';
import CommissionWithdrawTable from '../../Components/CommissionWithdrawTable/CommissionWithdrawTable';
import PaymentWithdrawTable from '../../Components/CommissionWithdrawTable/PaymentWithdrawTable';
import SectionSpinner from '../../Components/Spinner/SectionSpinner';
import { commissionWithdrawRef, paymentWithdrawCollectionRef, promoCollectionRef } from '../../firebase/fire-app';
const SalespersonWithdrawDetails = () => {
    const {userId} = useParams();
    useEffect(()=>{
        paymentWithdrawCollectionRef.where("salespersonId", "==", userId).orderBy("date", "desc").get().then(()=>{
            console.log("fetched");
        })
    },[userId])
    // const [promoCode, loading, error] = useDocumentData(promoCollectionRef.doc(promoId), {idField: "id"});
    const [withdraws, withdrawLoading, withdrawError] = useCollectionData(paymentWithdrawCollectionRef.where("salespersonId", "==", userId).orderBy("date", "desc"), {idField: "id"})
   
    return (
        <>
         <div className="jumbotron text-center">
                <h3>{userId} </h3>
                <button onClick={()=> console.log(withdraws)} className="btn btn-danger">LOg</button>
            </div>
            <div className="container py-5">
                {withdrawLoading && <SectionSpinner/> }
                {withdraws && withdraws.length > 0 && <PaymentWithdrawTable allData={withdraws}/> }
            </div>   
        </>
    );
};

export default SalespersonWithdrawDetails;