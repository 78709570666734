import React, { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useForm } from 'react-hook-form';
import InputWrapper from '../../Components/InputWrapper/InputWrapper';
import UserDetails from '../../Components/UserDetails/UserDetails';
import { fireStoreDB } from '../../firebase/fire-app';
import { useAuth } from '../../Hooks/useAuth';
import SearchUser from './SearchUser';


const paymentDocRef = fireStoreDB.collection("Payment").doc("Payment");
const salesPersonRef = fireStoreDB.collection("Payment").doc("SalesPersons").collection("SalesPersons");
const userInfoCollectionRef = fireStoreDB
    .collection("metadata")
    .doc("userMetadata")
    .collection("userInfo");
const subscriptionDocRef = fireStoreDB.collection("Payment").doc("Subscription");
const PageUpdateUser = () => {
    const { register, handleSubmit } = useForm();

    const [userId, setUserId] = useState("djT45xv9IgXQ7ocZDhBY5OB76563");
    const { currentUser } = useAuth()

    const onPaymentAdded = (data) => {

        userInfoCollectionRef.doc(data.userId).update({
            userType: data.userType,
            typeUpdatedBy: currentUser.id
        })
        // data.date = new Date();
        // data.salesPersonId = "0012";
        // const expiryDate = new Date(new Date().getTime() + (300 * 24 * 60 * 60 * 1000))
        // paymentDocRef.collection("AllPayments").add(data).then(() => {
        //     paymentDocRef.collection("Users").doc(data.userId).update({
        //         amount: firebase.firestore.FieldValue.increment(data.amount),
        //         expiryDate: expiryDate
        //     }).then(() => {
        //         salesPersonRef.doc(data.salesPersonId).update({
        //             totalAmount: firebase.firestore.FieldValue.increment(data.amount),
        //             currentBalance: firebase.firestore.FieldValue.increment(data.amount)
        //         })
        //     })
        // })
    }
    const onUserIdChanged = (e) => {
        setUserId(e.target.value)
    }
    const [userSub, setUserSub] = useState(null);
    const userTypes = ["Admin", "Campus Ambassador", "Teacher", "Free User", "Support Member", "Salesperson", "Student"];
    // useEffect(() => {
    //     subscriptionDocRef.collection("Users").doc(userId).get().then((doc)=> {
    //         const data = doc.data()
    //         setUserSub(data)
    //     })
    // }, [userId])

    return (
        <>
            <div className="container-fluid bg-light py-5 mb-5">
                <SearchUser />

            </div>

            <div className="container-fluid">
                <div className="row d-flex align-items-center">
                    <div className="col-md-8">
                        <form onSubmit={handleSubmit(onPaymentAdded)}>
                            <div className="row">
                                <InputWrapper col="12" label="UserId">
                                    <input onChange={onUserIdChanged} ref={register({ required: true })} name="userId" type="text" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col={6} label="User Type: ">
                                    <select ref={register({ required: true })} name="userType" className="custom-select">
                                        {userTypes.map(item => <option value={item}>{item}</option>)}
                                    </select>
                                </InputWrapper>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Update user</button>
                                </div>
                            </div>
                        </form>
                        {/* <form onSubmit={handleSubmit(onPaymentAdded)}>
                            <div className="row">
                                <InputWrapper col="12" label="UserId">
                                    <input onChange={onUserIdChanged} ref={register({ required: true })} name="userId" type="text" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="4" label="Amount">
                                    <input ref={register({ required: true })} name="amount" type="text" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="4" label="Method">
                                    <input ref={register({ required: true })} name="paymentMethod" type="text" className="form-control" />
                                </InputWrapper>
                                <InputWrapper col="4" label="TrxId">
                                    <input ref={register({ required: true })} name="transId" type="text" className="form-control" />
                                </InputWrapper>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary">Add payment</button>
                                </div>
                            </div>
                        </form> */}
                    </div>
                    <div className="col-md-4">
                        {userId && <UserDetails id={userId} />}
                        {/* <button onClick={()=> console.log(userSub)} className="btn btn-danger btn-sm">subs</button> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageUpdateUser;