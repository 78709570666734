import React from 'react';
import ReactDOM from 'react-dom';

// ant design styles
import "../node_modules/antd/dist/antd.less"
import "../node_modules/antd//lib/style/themes/default.less"
import "./styles/default-colors.less"

import "./SCSS/main.scss";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
